import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export class Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export class AccessType {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<Array<User>>;
};

export class Account {
  accountMovement?: Maybe<Array<AccountMovement>>;
  balance: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class AccountDto {
  balance: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class AccountExtractDto {
  month: Scalars['String'];
  movements?: Maybe<Array<MovementDto>>;
  year: Scalars['String'];
};

export class AccountExtractResponse {
  account?: Maybe<AccountDto>;
  extract?: Maybe<Array<AccountExtractDto>>;
};

export class AccountMovement {
  account: Account;
  balance: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  pdvPointDistributionUser?: Maybe<Array<PdvPointDistributionUser>>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
  value: Scalars['Float'];
};

export class ApprovedDistributionUser {
  campaignId: Scalars['String'];
  pdvId: Scalars['String'];
  reference: Scalars['String'];
};

export class AuthInput {
  cpf: Scalars['String'];
  origin?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export class AuthType {
  regulations: Array<VerifyAcceptRegulationResult>;
  terms: Array<VerifyAcceptTermResult>;
  token: Scalars['String'];
  user: User;
};

export class Banner {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class BannerPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Banner>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Campaign {
  award: Scalars['String'];
  campaignMaterial?: Maybe<Array<CampaignMaterial>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  mechanic: Scalars['String'];
  name: Scalars['String'];
  objectives: Scalars['String'];
  pdvPoint?: Maybe<Array<PdvPoint>>;
  start?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  urlFriendly?: Maybe<Scalars['String']>;
  urlImage?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class CampaignMaterial {
  campaign: Campaign;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  originalName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  urlMedia?: Maybe<Scalars['String']>;
};

export class CampaignPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Campaign>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class ChangePasswordInput {
  password: Scalars['String'];
  passwordOld: Scalars['String'];
};

export class Channel {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  narrativeChannel?: Maybe<Array<NarrativeChannel>>;
  updatedAt: Scalars['DateTime'];
};

export class Contact {
  answer?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  status: Scalars['String'];
  type?: Maybe<ContactType>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userAnswer?: Maybe<User>;
};

export class ContactPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results: Array<Contact>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class ContactType {
  contact?: Maybe<Array<Contact>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class CreateAnswerInput {
  isCorrect: Scalars['Boolean'];
  order: Scalars['Float'];
  text: Scalars['String'];
};

export class CreateBannerInput {
  end: Scalars['DateTime'];
  isActive: Scalars['Boolean'];
  link?: InputMaybe<Scalars['String']>;
  start: Scalars['DateTime'];
  title: Scalars['String'];
};

export class CreateCampaignInput {
  award: Scalars['String'];
  end: Scalars['DateTime'];
  isActive: Scalars['Boolean'];
  mechanic: Scalars['String'];
  name: Scalars['String'];
  objectives: Scalars['String'];
  start: Scalars['DateTime'];
};

export class CreateChannelInput {
  channelId: Scalars['String'];
  link: Scalars['String'];
};

export class CreateContactInput {
  description: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  typeId: Scalars['String'];
};

export class CreateContactTypeInput {
  name: Scalars['String'];
};

export class CreateFaqCategoryInput {
  name: Scalars['String'];
};

export class CreateFaqInput {
  answer: Scalars['String'];
  categoryId: Scalars['String'];
  question: Scalars['String'];
};

export class CreateHighlightInput {
  end: Scalars['DateTime'];
  isActive: Scalars['Boolean'];
  start: Scalars['DateTime'];
  title: Scalars['String'];
};

export class CreateHighlightStoryInput {
  highlightId: Scalars['String'];
};

export class CreateMaterialInput {
  description: Scalars['String'];
  end: Scalars['DateTime'];
  isActive: Scalars['Boolean'];
  start: Scalars['DateTime'];
  title: Scalars['String'];
};

export class CreateMultipleDistributionUser {
  campaignId: Scalars['String'];
  pdvId: Scalars['String'];
  pointUser: Array<CreatePointUser>;
  reference: Scalars['String'];
};

export class CreateMultiplePoints {
  campaignId: Scalars['String'];
  pointPdv: Array<CreatePointPdv>;
  reference: Scalars['String'];
};

export class CreateNarrativeInput {
  channel?: InputMaybe<Array<CreateChannelInput>>;
  description: Scalars['String'];
  end: Scalars['DateTime'];
  name: Scalars['String'];
  start: Scalars['DateTime'];
};

export class CreateNotificationInput {
  description?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAutomatic?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export class CreatePointPdv {
  pdvId: Scalars['String'];
  point: Scalars['Float'];
};

export class CreatePointUser {
  point: Scalars['Float'];
  userId: Scalars['String'];
};

export class CreatePostInput {
  description: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  postProfileId: Scalars['String'];
  start: Scalars['DateTime'];
  title: Scalars['String'];
  type: Scalars['String'];
  urlVideo?: InputMaybe<Scalars['String']>;
};

export class CreatePostProfileInput {
  name: Scalars['String'];
};

export class CreateQuestionInput {
  answers: Array<CreateAnswerInput>;
  points: Scalars['Float'];
  questionNumber: Scalars['Float'];
  text: Scalars['String'];
};

export class CreateQuizInput {
  end: Scalars['DateTime'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  questions: Array<CreateQuestionInput>;
  start: Scalars['DateTime'];
  title: Scalars['String'];
  trainingClassId: Scalars['String'];
};

export class CreateRegulationInput {
  description: Scalars['String'];
  name: Scalars['String'];
  textAccept: Scalars['String'];
};

export class CreateTermInput {
  description: Scalars['String'];
  textAccept: Scalars['String'];
  type: Scalars['String'];
};

export class CreateTrainingClassInput {
  /** video | pdf | podcast */
  contentType: Scalars['String'];
  /** only if video or podcast */
  contentUrl?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  duration: Scalars['Float'];
  title: Scalars['String'];
  /** only if video or podcast */
  trainingId: Scalars['String'];
};

export class CreateTrainingInput {
  description: Scalars['String'];
  title: Scalars['String'];
  trainingRoleIds?: InputMaybe<Array<Scalars['String']>>;
};

export class CreateUserInput {
  accessTypeId?: InputMaybe<Scalars['String']>;
  cpf: Scalars['String'];
  email: Scalars['String'];
  group?: InputMaybe<Scalars['String']>;
  jobRoleId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  pdvId?: InputMaybe<Scalars['String']>;
  profile: Scalars['String'];
  subGroup?: InputMaybe<Scalars['String']>;
};

export class CreateUserPostCommentInput {
  comment: Scalars['String'];
  postId: Scalars['String'];
};

export class DefaultMessage {
  message: Scalars['String'];
  status: Scalars['Int'];
};

export class EnumType {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  detail?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['String'];
};

export class Faq {
  answer: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  faqCategory: FaqCategory;
  id: Scalars['ID'];
  question: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class FaqCategory {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  faq?: Maybe<Array<Faq>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  termAccept?: Maybe<Array<UserTermAccept>>;
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class FaqCategoryPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<FaqCategory>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class FaqGroupList {
  category: FaqCategory;
  questions?: Maybe<Array<Faq>>;
};

export class FaqPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Faq>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class FirstAccessInput {
  cpf: Scalars['String'];
};

export class FirstAccessRegisterUserInput {
  birthday: Scalars['DateTime'];
  cpf: Scalars['String'];
  hasPet: Scalars['Boolean'];
  hasPetType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nickName: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  userAddress: RegisterUserAddress;
};

export class FirstAccessValidationCodeInput {
  code: Scalars['String'];
  cpf: Scalars['String'];
};

export class Goal {
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  goal: Scalars['Float'];
  goalType: GoalType;
  id: Scalars['ID'];
  importance: Scalars['Float'];
  pdv?: Maybe<Pdv>;
  percent: Scalars['Float'];
  position: Scalars['Float'];
  reference: Scalars['String'];
  result: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class GoalDto {
  goal?: Maybe<Array<GoalListDto>>;
  reference: Scalars['String'];
};

export class GoalImportPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<ImportFile>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class GoalListDto {
  description: Scalars['String'];
  goal: Scalars['Float'];
  percent: Scalars['Float'];
  result: Scalars['Float'];
};

export class GoalPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Goal>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class GoalType {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  format: Scalars['String'];
  goal?: Maybe<Array<Goal>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
  weight: Scalars['Float'];
};

export class Highlight {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  end: Scalars['DateTime'];
  highlightStories?: Maybe<Array<HighlightStory>>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  start: Scalars['DateTime'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class HighlightPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Highlight>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class HighlightStory {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  highlight: Highlight;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
};

export class ImportFile {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  importId: Scalars['String'];
  importType: Scalars['String'];
  originalName: Scalars['String'];
  urlFile: Scalars['String'];
  userCreated?: Maybe<User>;
};

export class JobRole {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  trainingRole?: Maybe<Array<TrainingRole>>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<Array<User>>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class ListReferenceDto {
  reference: Scalars['String'];
  value: Scalars['String'];
};

export class Material {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  materialItem?: Maybe<Array<MaterialItem>>;
  start?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class MaterialItem {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  material: Material;
  originalName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  urlMedia?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class MaterialPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Material>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class MovementDto {
  balance: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Float'];
};

export class Mutation {
  /** Abort Quiz */
  abortQuiz: DefaultMessage;
  acceptRegulation: DefaultMessage;
  acceptTerm: DefaultMessage;
  /** Approve Self Register User */
  approveSelfRegister: DefaultMessage;
  approvedDistributionForUser: DefaultMessage;
  changePassword: DefaultMessage;
  /** Registers user who consume the content */
  consumeContent: DefaultMessage;
  createBanner: Banner;
  createCampaign: Campaign;
  /** Create new Contact */
  createContact: Contact;
  /** Create new Contact Type */
  createContactType: ContactType;
  createFaq: Faq;
  createFaqCategory: FaqCategory;
  /** Create new Highlight */
  createHighlight: Highlight;
  /** Create new Highlight Story */
  createHighlightStory: HighlightStory;
  createMaterial: Material;
  createMultipleDistributionForUser: DefaultMessage;
  createMultiplePointsForPdv: DefaultMessage;
  createNarrative: Narrative;
  createNotification: Notification;
  createPost: Post;
  createPostProfile: PostProfile;
  createQuiz: Quiz;
  createRegulation: Regulation;
  createTerm: Term;
  createTraining: Training;
  createTrainingClass: TrainingClass;
  /** Create new User in admin for first access */
  createUser: User;
  /** Create user comment */
  createUserPostComment: DefaultMessage;
  /** Create user liked */
  createUserPostLike: DefaultMessage;
  /** Delete All User notifications */
  deleteAllNotifications: DefaultMessage;
  /** Remove Campaign By Id */
  deleteCampaign: DefaultMessage;
  /** Remove Campaign Material Item By Id */
  deleteCampaignMaterialItem: DefaultMessage;
  /** Remove FAQ By Id */
  deleteFaq: DefaultMessage;
  /** Remove FAQ category By Id */
  deleteFaqCategory: DefaultMessage;
  /** Remove Highlight By Id */
  deleteHighlight: DefaultMessage;
  /** Remove Highlight Story By Id */
  deleteHighlightStory: DefaultMessage;
  /** Remove Material By Id */
  deleteMaterial: DefaultMessage;
  /** Remove Material Item By Id */
  deleteMaterialItem: DefaultMessage;
  /** Remove Narrative By Id */
  deleteNarrative: DefaultMessage;
  /** Remove Notification By Id */
  deleteNotification: DefaultMessage;
  deletePointsForPdv: DefaultMessage;
  /** Remove Post By Id */
  deletePost: DefaultMessage;
  /** Remove Post Profile By Id */
  deletePostProfile: DefaultMessage;
  /** Delete Quiz By Id */
  deleteQuiz: DefaultMessage;
  /** Remove Regulation By Id */
  deleteRegulation: DefaultMessage;
  /** Remove Term By Id */
  deleteTerm: DefaultMessage;
  /** Remove Training Content Item By Id */
  deleteTrainingContentItem: DefaultMessage;
  /** Remove Training Material Item By Id */
  deleteTrainingMaterialItem: DefaultMessage;
  /** Remove User By Id */
  deleteUser: DefaultMessage;
  /** Finish Quiz */
  finishQuiz: QuizResultDto;
  /** First access send e-mail code */
  firstAccess: DefaultMessage;
  /** First access register user */
  firstAccessRegisterUser: User;
  /** First access validation code */
  firstAccessValidationCode: User;
  forgotPassword: DefaultMessage;
  login: AuthType;
  /** Read All User notifications */
  readAllNotifications: DefaultMessage;
  /** Read User notification */
  readNotification: DefaultMessage;
  removeBanner: DefaultMessage;
  /** Remove Contact Type */
  removeContactType: DefaultMessage;
  removeTraining: DefaultMessage;
  removeTrainingClass: DefaultMessage;
  reproveDistributionForUser: DefaultMessage;
  /** Reprove Self Register User */
  reproveSelfRegister: DefaultMessage;
  resetPassword: DefaultMessage;
  /** Update Contact */
  respondContact: Contact;
  /** Save question answer */
  saveQuestionAnswer: DefaultMessage;
  /** Self Register User in web */
  selfRegister: DefaultMessage;
  updateBanner: Banner;
  /** Update Campaign */
  updateCampaign: Campaign;
  /** Update Contact Type */
  updateContactType: ContactType;
  /** Update FAQ */
  updateFaq: Faq;
  /** Update FAQ category */
  updateFaqCategory: FaqCategory;
  /** Update Highlight */
  updateHighlight: Highlight;
  /** Update Material */
  updateMaterial: Material;
  /** Update Narrative */
  updateNarrative: Narrative;
  /** Update Notification */
  updateNotification: Notification;
  /** Update Post */
  updatePost: Post;
  /** Update Post Profile */
  updatePostProfile: PostProfile;
  /** Update Quiz */
  updateQuiz: Quiz;
  /** Update Regulation in admin */
  updateRegulation: Regulation;
  /** Update status comment */
  updateStatusComment: DefaultMessage;
  /** Update Term in admin */
  updateTerm: Term;
  updateTraining: Training;
  updateTrainingClass: TrainingClass;
  /** Update User in admin */
  updateUser: User;
  /** Update User Profile */
  updateUserProfile: User;
  verifyAcceptRegulation: Array<VerifyAcceptRegulationResult>;
  verifyAcceptTerm: Array<VerifyAcceptTermResult>;
};


export class MutationAbortQuizArgs {
  quizId: Scalars['String'];
};


export class MutationAcceptRegulationArgs {
  regulationId: Scalars['String'];
};


export class MutationAcceptTermArgs {
  termId: Scalars['String'];
};


export class MutationApproveSelfRegisterArgs {
  userId: Scalars['String'];
};


export class MutationApprovedDistributionForUserArgs {
  data: ApprovedDistributionUser;
};


export class MutationChangePasswordArgs {
  data: ChangePasswordInput;
};


export class MutationConsumeContentArgs {
  trainingClassId: Scalars['String'];
};


export class MutationCreateBannerArgs {
  data: CreateBannerInput;
};


export class MutationCreateCampaignArgs {
  data: CreateCampaignInput;
};


export class MutationCreateContactArgs {
  data: CreateContactInput;
};


export class MutationCreateContactTypeArgs {
  data: CreateContactTypeInput;
};


export class MutationCreateFaqArgs {
  data: CreateFaqInput;
};


export class MutationCreateFaqCategoryArgs {
  data: CreateFaqCategoryInput;
};


export class MutationCreateHighlightArgs {
  data: CreateHighlightInput;
};


export class MutationCreateHighlightStoryArgs {
  data: CreateHighlightStoryInput;
};


export class MutationCreateMaterialArgs {
  data: CreateMaterialInput;
};


export class MutationCreateMultipleDistributionForUserArgs {
  data: CreateMultipleDistributionUser;
};


export class MutationCreateMultiplePointsForPdvArgs {
  data: CreateMultiplePoints;
};


export class MutationCreateNarrativeArgs {
  data: CreateNarrativeInput;
};


export class MutationCreateNotificationArgs {
  data: CreateNotificationInput;
};


export class MutationCreatePostArgs {
  data: CreatePostInput;
};


export class MutationCreatePostProfileArgs {
  data: CreatePostProfileInput;
};


export class MutationCreateQuizArgs {
  data: CreateQuizInput;
};


export class MutationCreateRegulationArgs {
  data: CreateRegulationInput;
};


export class MutationCreateTermArgs {
  data: CreateTermInput;
};


export class MutationCreateTrainingArgs {
  data: CreateTrainingInput;
};


export class MutationCreateTrainingClassArgs {
  data: CreateTrainingClassInput;
};


export class MutationCreateUserArgs {
  data: CreateUserInput;
};


export class MutationCreateUserPostCommentArgs {
  data: CreateUserPostCommentInput;
};


export class MutationCreateUserPostLikeArgs {
  postId: Scalars['String'];
};


export class MutationDeleteCampaignArgs {
  id: Scalars['String'];
};


export class MutationDeleteCampaignMaterialItemArgs {
  id: Scalars['String'];
};


export class MutationDeleteFaqArgs {
  id: Scalars['String'];
};


export class MutationDeleteFaqCategoryArgs {
  id: Scalars['String'];
};


export class MutationDeleteHighlightArgs {
  id: Scalars['String'];
};


export class MutationDeleteHighlightStoryArgs {
  id: Scalars['String'];
};


export class MutationDeleteMaterialArgs {
  id: Scalars['String'];
};


export class MutationDeleteMaterialItemArgs {
  id: Scalars['String'];
};


export class MutationDeleteNarrativeArgs {
  id: Scalars['String'];
};


export class MutationDeleteNotificationArgs {
  id: Scalars['String'];
};


export class MutationDeletePointsForPdvArgs {
  pdvPointId: Scalars['String'];
};


export class MutationDeletePostArgs {
  id: Scalars['String'];
};


export class MutationDeletePostProfileArgs {
  id: Scalars['String'];
};


export class MutationDeleteQuizArgs {
  id: Scalars['String'];
};


export class MutationDeleteRegulationArgs {
  id: Scalars['String'];
};


export class MutationDeleteTermArgs {
  id: Scalars['String'];
};


export class MutationDeleteTrainingContentItemArgs {
  id: Scalars['String'];
};


export class MutationDeleteTrainingMaterialItemArgs {
  id: Scalars['String'];
};


export class MutationDeleteUserArgs {
  id: Scalars['String'];
};


export class MutationFinishQuizArgs {
  quizId: Scalars['String'];
};


export class MutationFirstAccessArgs {
  data: FirstAccessInput;
};


export class MutationFirstAccessRegisterUserArgs {
  data: FirstAccessRegisterUserInput;
};


export class MutationFirstAccessValidationCodeArgs {
  data: FirstAccessValidationCodeInput;
};


export class MutationForgotPasswordArgs {
  email: Scalars['String'];
  type: Scalars['String'];
};


export class MutationLoginArgs {
  data: AuthInput;
};


export class MutationReadNotificationArgs {
  userNotificationId: Scalars['String'];
};


export class MutationRemoveBannerArgs {
  id: Scalars['String'];
};


export class MutationRemoveContactTypeArgs {
  id: Scalars['String'];
};


export class MutationRemoveTrainingArgs {
  id: Scalars['String'];
};


export class MutationRemoveTrainingClassArgs {
  id: Scalars['String'];
};


export class MutationReproveDistributionForUserArgs {
  data: ReproveDistributionUser;
};


export class MutationReproveSelfRegisterArgs {
  reprovedReason: Scalars['String'];
  userId: Scalars['String'];
};


export class MutationResetPasswordArgs {
  data: ResetPasswordInput;
};


export class MutationRespondContactArgs {
  data: UpdateContactInput;
};


export class MutationSaveQuestionAnswerArgs {
  data: SaveQuestionAnswerInput;
};


export class MutationSelfRegisterArgs {
  data: SelfRegisterInput;
};


export class MutationUpdateBannerArgs {
  data: UpdateBannerInput;
};


export class MutationUpdateCampaignArgs {
  data: UpdateCampaignInput;
};


export class MutationUpdateContactTypeArgs {
  data: UpdateContactTypeInput;
};


export class MutationUpdateFaqArgs {
  data: UpdateFaqInput;
};


export class MutationUpdateFaqCategoryArgs {
  data: UpdateFaqCategoryInput;
};


export class MutationUpdateHighlightArgs {
  data: UpdateHighlightInput;
};


export class MutationUpdateMaterialArgs {
  data: UpdateMaterialInput;
};


export class MutationUpdateNarrativeArgs {
  data: UpdateNarrativeInput;
};


export class MutationUpdateNotificationArgs {
  data: UpdateNotificationInput;
};


export class MutationUpdatePostArgs {
  data: UpdatePostInput;
};


export class MutationUpdatePostProfileArgs {
  data: UpdatePostProfileInput;
};


export class MutationUpdateQuizArgs {
  data: UpdateQuizInput;
};


export class MutationUpdateRegulationArgs {
  data: UpdateRegulationInput;
};


export class MutationUpdateStatusCommentArgs {
  data: UpdateStatusCommentInput;
};


export class MutationUpdateTermArgs {
  data: UpdateTermInput;
};


export class MutationUpdateTrainingArgs {
  data: UpdateTrainingInput;
};


export class MutationUpdateTrainingClassArgs {
  data: UpdateTrainingClassInput;
};


export class MutationUpdateUserArgs {
  data: UpdateUserInput;
};


export class MutationUpdateUserProfileArgs {
  data: UpdateUserProfileInput;
};

export class Narrative {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  narrativeChannel?: Maybe<Array<NarrativeChannel>>;
  start?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class NarrativeChannel {
  channel: Channel;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  narrative: Narrative;
  updatedAt: Scalars['DateTime'];
};

export class NarrativePage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Narrative>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Notification {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isAutomatic: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  notification?: Maybe<Array<NotificationUser>>;
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class NotificationDto {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isAutomatic: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class NotificationPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Notification>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class NotificationUser {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  notification: Notification;
  readAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class NotificationUserDto {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  notification: NotificationDto;
  readAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export class Pdv {
  address?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  codeSoldBranch?: Maybe<Scalars['String']>;
  codeSoldBroker?: Maybe<Scalars['String']>;
  codeTerritory?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  district?: Maybe<Scalars['String']>;
  goal?: Maybe<Array<Goal>>;
  group?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  linkShield?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameProprietary?: Maybe<Scalars['String']>;
  pdvPoint?: Maybe<Array<PdvPoint>>;
  plant?: Maybe<Scalars['String']>;
  ranking?: Maybe<Array<Ranking>>;
  registrationNumberBranch?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  state?: Maybe<Scalars['String']>;
  subGroup?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<Array<User>>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userPdv?: Maybe<Array<UserPdv>>;
  userUpdated?: Maybe<User>;
  zipCode?: Maybe<Scalars['String']>;
};

export class PdvPoint {
  campaign: Campaign;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isApproved?: Maybe<Scalars['Boolean']>;
  pdv: Pdv;
  pdvPointDistribution?: Maybe<Array<PdvPointDistribution>>;
  points: Scalars['Float'];
  reference: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userQtd: Scalars['Float'];
  userUpdated?: Maybe<User>;
};

export class PdvPointDistribution {
  approvedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  pdvPoint: PdvPoint;
  pdvPointDistributionUser?: Maybe<Array<PdvPointDistributionUser>>;
  reprovedAt?: Maybe<Scalars['DateTime']>;
  reprovedReason?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userApproved?: Maybe<User>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userQtd: Scalars['Float'];
  userReproved?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class PdvPointDistributionPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<PdvPointDistribution>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class PdvPointDistributionUser {
  accountMovement?: Maybe<AccountMovement>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  pdvPointDistribution: PdvPointDistribution;
  points: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class Post {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  postComment?: Maybe<Array<PostComment>>;
  postLike?: Maybe<Array<PostLike>>;
  postProfile: PostProfile;
  qtdComment?: Maybe<Scalars['Float']>;
  qtdCommentApproved?: Maybe<Scalars['Float']>;
  qtdCommentPending?: Maybe<Scalars['Float']>;
  qtdCommentReproved?: Maybe<Scalars['Float']>;
  qtdLike?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  urlVideo?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userLiked?: Maybe<Scalars['Boolean']>;
  userUpdated?: Maybe<User>;
};

export class PostComment {
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  post: Post;
  status: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedAtStatus?: Maybe<Scalars['DateTime']>;
  user: User;
  userUpdatedStatus?: Maybe<User>;
};

export class PostCommentsPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<PostComment>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class PostLike {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  post: Post;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export class PostPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Post>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class PostProfile {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  post?: Maybe<Array<Post>>;
  updatedAt: Scalars['DateTime'];
  urlImage?: Maybe<Scalars['String']>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class PostProfilePage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<PostProfile>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Query {
  /** Return Access Type By ID */
  accessTypeById: AccessType;
  /** Return Account By user */
  accountByUser?: Maybe<Account>;
  /** Return Pdv points Active By campaign, reference and pdv */
  activePdvPoints: PdvPoint;
  /** Return Pdv points Distribution active By campaign, reference and pdv */
  activePdvPointsDistribution: PdvPointDistribution;
  /** Return All Banners For Admin */
  adminBanners: BannerPage;
  /** Return All Highlight for Admin */
  adminHighlight: HighlightPage;
  /** Return All Training For Admin */
  adminTraining: TrainingPage;
  /** Return Banners By ID */
  banner: Banner;
  /** Return All Banners */
  banners: Array<Banner>;
  /** Return Campaign By URL Friendly */
  campaignByFriendlyUrl: Campaign;
  /** Return Campaign By ID */
  campaignById: Campaign;
  /** Return All Campaign */
  campaignList: Array<Campaign>;
  /** Return All Campaign Paged */
  campaignListPaged: CampaignPage;
  /** Return Campaign Material Item By ID */
  campaignMaterialItemById: CampaignMaterial;
  /** Return Channel By ID */
  channelById: Channel;
  /** Return All Channel List */
  channelList: Array<Channel>;
  /** Return Class By training ID */
  classByTrainingId: Array<TrainingClass>;
  /** Return Comment By ID */
  commentById: PostComment;
  /** Return Contact by Id */
  contact: Contact;
  /** Return Contact Type By Id */
  contactType: ContactType;
  /** Return all Contact Types */
  contactTypes: Array<ContactType>;
  /** Search all Contact paged */
  contacts: ContactPage;
  /** Return User Logged */
  currentUser: User;
  /** Return All By Type */
  enumByType: Array<EnumType>;
  /** Return Enum Type by Id */
  enumTypeById: EnumType;
  /** Return All Enum Types */
  enumTypes: Array<EnumType>;
  /** Return Extract points By user CPF or email */
  extractByCpfOrEmail: AccountExtractResponse;
  /** Return Extract points By user */
  extractByUser?: Maybe<AccountExtractResponse>;
  /** Return Faq By ID */
  faqById: Faq;
  /** Return Faq Category By ID */
  faqCategoryById: FaqCategory;
  /** Return All FAQ category */
  faqCategoryList: Array<FaqCategory>;
  /** Return All FAQ grouped */
  faqGroupList: Array<FaqGroupList>;
  /** Return All FAQ */
  faqList: Array<Faq>;
  /** Return Highlight By ID */
  highlight: Highlight;
  /** Return Highlight Story By ID */
  highlightStory: HighlightStory;
  /** Return All Highlights */
  highlights: Array<Highlight>;
  /** Return Pdv points Inactive By campaign, reference and pdv */
  inactivePdvPoints: PdvPoint;
  /** Return Job Role By ID */
  jobRoleById: JobRole;
  /** Return Latest Campaign */
  latestCampaign: Campaign;
  /** Return All Access Type List */
  listAccessType: Array<AccessType>;
  /** Return All CNPJ PDV List */
  listCnpjPdv: Array<Scalars['String']>;
  /** Return All CPF user List */
  listCpfUser: Array<Scalars['String']>;
  /** Return All Goal PDV type "fixo" */
  listGoalPdvFixo?: Maybe<GoalDto>;
  /** Return All Goal PDV type "variável" */
  listGoalPdvVariavel?: Maybe<GoalDto>;
  /** Return All Goal User type "fixo" */
  listGoalUserFixo?: Maybe<GoalDto>;
  /** Return All Goal User type "variável" */
  listGoalUserVariavel?: Maybe<GoalDto>;
  /** Return All Import File by Type */
  listImportFileByType: Array<ImportFile>;
  /** Return All Job Role List */
  listJobRole: Array<JobRole>;
  /** Return All PDV List */
  listPdv: Array<Pdv>;
  /** Return Pdv points for distributions web/app */
  listPdvPointForDistribution: Array<PdvPoint>;
  /** Return Pdv points distributed web/app */
  listPdvPointsDistributed: Array<PdvPointDistribution>;
  /** Return List References By campaign */
  listReferenceByCampaign: Array<ListReferenceDto>;
  /** Return Material By ID */
  materialById: Material;
  /** Return Material Item By ID */
  materialItemById: MaterialItem;
  /** Return All Material */
  materialList: Array<Material>;
  /** Return Narrative By ID */
  narrativeById: Narrative;
  /** Return All Narrative List */
  narrativeList: Array<Narrative>;
  /** Return Notification By ID */
  notificationById: Notification;
  /** Return All Notification By User */
  notificationsByUser: UserNotificationDto;
  /** Return PDV By ID */
  pdvById: Pdv;
  /** Return Pdv points By campaign and reference  */
  pdvPoints: Array<PdvPoint>;
  /** Return Pdv points By Id */
  pdvPointsById: PdvPoint;
  /** Return Pdv points distributions By Id */
  pdvPointsDistributionById: PdvPointDistribution;
  /** Return Post By ID */
  postById: Post;
  /** Return Post With Comments Approved By ID */
  postCommentsApprovedById: Post;
  /** Return All Post */
  postList: Array<Post>;
  /** Return Post Profile By ID */
  postProfileById: PostProfile;
  /** Return All Post Profile */
  postProfileList: Array<PostProfile>;
  /** Return Question By Quiz ID */
  questionByQuizId: QuizQuestionDto;
  /** Return Quiz By ID */
  quizById: Quiz;
  /** Return Quiz By ID In ADMIN */
  quizByIdForAdmin: Quiz;
  /** Return All Ranking PDV */
  rankingAllPdv?: Maybe<RankingAllDto>;
  /** Return All Ranking User */
  rankingAllUser?: Maybe<RankingAllDto>;
  /** Return Ranking By PDV */
  rankingByPdv?: Maybe<RankingDto>;
  /** Return Ranking By User */
  rankingByUser?: Maybe<RankingDto>;
  /** Return Regulation By ID */
  regulationById: Regulation;
  /** Return All Regulations */
  regulationList: Array<Regulation>;
  /** Return Results All Quizzes */
  resultsAllQuizzes: QuizResultPage;
  /** Return Results By Quiz ID */
  resultsQuizById: QuizResultPage;
  /** Return All Training */
  roles: Array<JobRole>;
  /** Return All Campaign paged */
  searchAllCampaign: CampaignPage;
  /** Return All Comments paged */
  searchAllComments: PostCommentsPage;
  /** Return All FAQ paged */
  searchAllFaq: FaqPage;
  /** Return All FAQ category paged */
  searchAllFaqCategory: FaqCategoryPage;
  /** Return All Material paged */
  searchAllMaterial: MaterialPage;
  /** Return All Narrative paged */
  searchAllNarrative: NarrativePage;
  /** Return All Notification paged */
  searchAllNotification: NotificationPage;
  /** Return All Post paged */
  searchAllPost: PostPage;
  /** Return All Post Profile paged */
  searchAllPostProfile: PostProfilePage;
  /** Return All Quiz paged */
  searchAllQuiz: QuizPage;
  /** Return All Regulations Admin */
  searchAllRegulations: RegulationPage;
  /** Return All Goal PDV paged */
  searchGoalPdv: GoalPage;
  /** Return All Goal User paged */
  searchGoalUser: GoalPage;
  /** Return All File Goal PDV paged */
  searchImportFileGoalPdv: GoalImportPage;
  /** Return All File Goal User paged */
  searchImportFileGoalUser: GoalImportPage;
  /** Return All File Radar paged */
  searchImportFileRadar: RadarPage;
  /** Return All File Ranking PDV paged */
  searchImportFileRankingPdv: RankingImportPage;
  /** Return All File Ranking User paged */
  searchImportFileRankingUser: RankingImportPage;
  /** Return Pdv points distributions paged */
  searchPdvPointsDistribution: PdvPointDistributionPage;
  /** Return All Radar paged */
  searchRadar: UserPdvPage;
  /** Return All Ranking PDV paged */
  searchRankingPdv: RankingPage;
  /** Return All Ranking User paged */
  searchRankingUser: RankingPage;
  /** Return State By ID */
  state: State;
  /** Return All States */
  states: Array<State>;
  /** Return Term By ID */
  termById: Term;
  /** Return All Terms */
  termList: Array<Term>;
  /** Return All Terms By Type */
  termListByType: Term;
  /** Return Training By ID */
  training: Training;
  /** Return Class By ID */
  trainingClass: TrainingClass;
  /** Return Training By ID Intern */
  trainingIntern: Training;
  /** Return All Training */
  trainingList: Array<Training>;
  /** Return All Training */
  trainings: Array<Training>;
  /** Return User By Id */
  userById: User;
  /** Return All users paged */
  users: UserPage;
  /** Return All users by PDV */
  usersByPdvId: Array<User>;
  /** Return All users by profile */
  usersByProfile: Array<User>;
  /** Return All users Pending Self Register paged */
  usersPendingSelfRegister: UserPage;
  /** Check CPF */
  validateCPF: Scalars['Boolean'];
  /** Check E-mail */
  validateEmail: Scalars['Boolean'];
};


export class QueryAccessTypeByIdArgs {
  id: Scalars['String'];
};


export class QueryActivePdvPointsArgs {
  campaignId: Scalars['String'];
  pdvId: Scalars['String'];
  reference: Scalars['String'];
};


export class QueryActivePdvPointsDistributionArgs {
  campaignId: Scalars['String'];
  pdvId: Scalars['String'];
  reference: Scalars['String'];
};


export class QueryAdminBannersArgs {
  data: SearchBannerInput;
};


export class QueryAdminHighlightArgs {
  data: SearchHighlightInput;
};


export class QueryAdminTrainingArgs {
  data: SearchTrainingInput;
};


export class QueryBannerArgs {
  id: Scalars['String'];
};


export class QueryCampaignByFriendlyUrlArgs {
  urlFriendly: Scalars['String'];
};


export class QueryCampaignByIdArgs {
  id: Scalars['String'];
};


export class QueryCampaignListPagedArgs {
  page: Scalars['Float'];
};


export class QueryCampaignMaterialItemByIdArgs {
  id: Scalars['String'];
};


export class QueryChannelByIdArgs {
  id: Scalars['String'];
};


export class QueryClassByTrainingIdArgs {
  trainingId: Scalars['String'];
};


export class QueryCommentByIdArgs {
  id: Scalars['String'];
};


export class QueryContactArgs {
  id: Scalars['String'];
};


export class QueryContactTypeArgs {
  id: Scalars['String'];
};


export class QueryContactsArgs {
  data: SearchContactInput;
};


export class QueryEnumByTypeArgs {
  type: Scalars['String'];
};


export class QueryEnumTypeByIdArgs {
  id: Scalars['String'];
};


export class QueryExtractByCpfOrEmailArgs {
  data: SearchExtractCpfOrEmailInput;
};


export class QueryExtractByUserArgs {
  data: SearchExtractUserInput;
};


export class QueryFaqByIdArgs {
  id: Scalars['String'];
};


export class QueryFaqCategoryByIdArgs {
  id: Scalars['String'];
};


export class QueryHighlightArgs {
  id: Scalars['String'];
};


export class QueryHighlightStoryArgs {
  id: Scalars['String'];
};


export class QueryInactivePdvPointsArgs {
  campaignId: Scalars['String'];
  pdvId: Scalars['String'];
  reference: Scalars['String'];
};


export class QueryJobRoleByIdArgs {
  id: Scalars['String'];
};


export class QueryListGoalPdvFixoArgs {
  reference: Scalars['String'];
};


export class QueryListGoalPdvVariavelArgs {
  reference: Scalars['String'];
};


export class QueryListGoalUserFixoArgs {
  reference: Scalars['String'];
};


export class QueryListGoalUserVariavelArgs {
  reference: Scalars['String'];
};


export class QueryListImportFileByTypeArgs {
  data: SearchImportFileInput;
};


export class QueryListPdvPointForDistributionArgs {
  data: SearchPdvDistributionInput;
};


export class QueryListPdvPointsDistributedArgs {
  data: SearchPdvDistributionInput;
};


export class QueryListReferenceByCampaignArgs {
  campaignId: Scalars['String'];
};


export class QueryMaterialByIdArgs {
  id: Scalars['String'];
};


export class QueryMaterialItemByIdArgs {
  id: Scalars['String'];
};


export class QueryNarrativeByIdArgs {
  id: Scalars['String'];
};


export class QueryNotificationByIdArgs {
  id: Scalars['String'];
};


export class QueryPdvByIdArgs {
  id: Scalars['String'];
};


export class QueryPdvPointsArgs {
  campaignId: Scalars['String'];
  reference: Scalars['String'];
};


export class QueryPdvPointsByIdArgs {
  pdvPointId: Scalars['String'];
};


export class QueryPdvPointsDistributionByIdArgs {
  pdvPointDistributionId: Scalars['String'];
};


export class QueryPostByIdArgs {
  id: Scalars['String'];
};


export class QueryPostCommentsApprovedByIdArgs {
  id: Scalars['String'];
};


export class QueryPostProfileByIdArgs {
  id: Scalars['String'];
};


export class QueryQuestionByQuizIdArgs {
  quizId: Scalars['String'];
};


export class QueryQuizByIdArgs {
  id: Scalars['String'];
};


export class QueryQuizByIdForAdminArgs {
  id: Scalars['String'];
};


export class QueryRankingAllPdvArgs {
  reference: Scalars['String'];
};


export class QueryRankingAllUserArgs {
  reference: Scalars['String'];
};


export class QueryRankingByPdvArgs {
  reference: Scalars['String'];
};


export class QueryRankingByUserArgs {
  reference: Scalars['String'];
};


export class QueryRegulationByIdArgs {
  id: Scalars['String'];
};


export class QueryResultsAllQuizzesArgs {
  data: SearchListResultInput;
};


export class QueryResultsQuizByIdArgs {
  data: SearchListResultInput;
};


export class QuerySearchAllCampaignArgs {
  data: SearchCampaignInput;
};


export class QuerySearchAllCommentsArgs {
  data: SearchPostCommentsInput;
};


export class QuerySearchAllFaqArgs {
  data: SearchFaqInput;
};


export class QuerySearchAllFaqCategoryArgs {
  data: SearchFaqCategoryInput;
};


export class QuerySearchAllMaterialArgs {
  data: SearchMaterialInput;
};


export class QuerySearchAllNarrativeArgs {
  data: SearchNarrativeInput;
};


export class QuerySearchAllNotificationArgs {
  data: SearchNotificationInput;
};


export class QuerySearchAllPostArgs {
  data: SearchPostInput;
};


export class QuerySearchAllPostProfileArgs {
  data: SearchPostProfileInput;
};


export class QuerySearchAllQuizArgs {
  data: SearchQuizInput;
};


export class QuerySearchAllRegulationsArgs {
  data: SearchRegulationInput;
};


export class QuerySearchGoalPdvArgs {
  data: SearchGoalInput;
};


export class QuerySearchGoalUserArgs {
  data: SearchGoalInput;
};


export class QuerySearchImportFileGoalPdvArgs {
  data: SearchImportGoalInput;
};


export class QuerySearchImportFileGoalUserArgs {
  data: SearchImportGoalInput;
};


export class QuerySearchImportFileRadarArgs {
  data: SearchImportRadarInput;
};


export class QuerySearchImportFileRankingPdvArgs {
  data: SearchImportRankingInput;
};


export class QuerySearchImportFileRankingUserArgs {
  data: SearchImportRankingInput;
};


export class QuerySearchPdvPointsDistributionArgs {
  data: SearchPdvDistributionInput;
};


export class QuerySearchRadarArgs {
  data: SearchRadarInput;
};


export class QuerySearchRankingPdvArgs {
  data: SearchRankingInput;
};


export class QuerySearchRankingUserArgs {
  data: SearchRankingInput;
};


export class QueryStateArgs {
  id: Scalars['String'];
};


export class QueryTermByIdArgs {
  id: Scalars['String'];
};


export class QueryTermListByTypeArgs {
  type: Scalars['String'];
};


export class QueryTrainingArgs {
  id: Scalars['String'];
};


export class QueryTrainingClassArgs {
  id: Scalars['String'];
};


export class QueryTrainingInternArgs {
  id: Scalars['String'];
};


export class QueryUserByIdArgs {
  id: Scalars['String'];
};


export class QueryUsersArgs {
  data: SearchUserInput;
};


export class QueryUsersByPdvIdArgs {
  keyword?: InputMaybe<Scalars['String']>;
  pdvId: Scalars['String'];
};


export class QueryUsersByProfileArgs {
  profile: Scalars['String'];
};


export class QueryUsersPendingSelfRegisterArgs {
  data: SearchUserInput;
};


export class QueryValidateCpfArgs {
  cpf: Scalars['String'];
};


export class QueryValidateEmailArgs {
  email: Scalars['String'];
};

export class Quiz {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  punctuation?: Maybe<Scalars['Float']>;
  quizQuestion?: Maybe<Array<QuizQuestion>>;
  start?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  trainingClass: TrainingClass;
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class QuizListResultDto {
  correctAnswers?: Maybe<Scalars['Float']>;
  percentageCorrect?: Maybe<Scalars['Float']>;
  quizName?: Maybe<Scalars['String']>;
  quizUserSituationFinishDate?: Maybe<Scalars['DateTime']>;
  quizUserSituationStartDate?: Maybe<Scalars['DateTime']>;
  quizUserSituationStatus?: Maybe<Scalars['String']>;
  totalPoints?: Maybe<Scalars['Float']>;
  trainingClassName?: Maybe<Scalars['String']>;
  trainingName?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export class QuizPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Quiz>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class QuizQuestion {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  points?: Maybe<Scalars['Float']>;
  questionNumber: Scalars['Float'];
  quiz: Quiz;
  quizQuestionAnswer?: Maybe<Array<QuizQuestionAnswer>>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class QuizQuestionAnswer {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isCorrectForAdmin?: Maybe<Scalars['Boolean']>;
  ordination: Scalars['Float'];
  quizQuestion: QuizQuestion;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class QuizQuestionDto {
  answers: Array<QuizQuestionAnswer>;
  currentQuestion: Scalars['Float'];
  finishedIn?: Maybe<Scalars['DateTime']>;
  question: QuizQuestion;
  startedIn?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  totalQuestions: Scalars['Float'];
};

export class QuizResultDto {
  correctAnswers: Scalars['Float'];
  finishedIn?: Maybe<Scalars['DateTime']>;
  percentageCorrect: Scalars['Float'];
  startedIn?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  totalPoints: Scalars['Float'];
  totalQuestions: Scalars['Float'];
};

export class QuizResultPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<QuizListResultDto>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class RadarPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<ImportFile>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class Ranking {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  pdv?: Maybe<Pdv>;
  points: Scalars['Float'];
  position: Scalars['Float'];
  reference: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class RankingAAllDto {
  name: Scalars['String'];
  points: Scalars['Float'];
  position: Scalars['Float'];
};

export class RankingAdto {
  points: Scalars['Float'];
  position: Scalars['Float'];
};

export class RankingAllDto {
  annual: Array<RankingAAllDto>;
  monthly: Array<RankingMAllDto>;
  reference: Scalars['String'];
  trimester: Array<RankingTAllDto>;
};

export class RankingDto {
  annual: RankingAdto;
  monthly: RankingMdto;
  reference: Scalars['String'];
  trimester: RankingTdto;
  updatedAt: Scalars['DateTime'];
};

export class RankingImportPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<ImportFile>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class RankingMAllDto {
  name: Scalars['String'];
  points: Scalars['Float'];
  position: Scalars['Float'];
};

export class RankingMdto {
  points: Scalars['Float'];
  position: Scalars['Float'];
};

export class RankingPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Ranking>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class RankingTAllDto {
  name: Scalars['String'];
  points: Scalars['Float'];
  position: Scalars['Float'];
};

export class RankingTdto {
  points: Scalars['Float'];
  position: Scalars['Float'];
};

export class RegisterUserAddress {
  address: Scalars['String'];
  city: Scalars['String'];
  complement?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  district: Scalars['String'];
  number: Scalars['String'];
  state: Scalars['String'];
  zipCode?: InputMaybe<Scalars['String']>;
};

export class Regulation {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regulationAccept?: Maybe<Array<UserRegulationAccept>>;
  textAccept: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class RegulationPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Regulation>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class ReproveDistributionUser {
  campaignId: Scalars['String'];
  pdvId: Scalars['String'];
  reference: Scalars['String'];
  reprovedReason: Scalars['String'];
};

export class ResetPasswordInput {
  code: Scalars['String'];
  newPassword: Scalars['String'];
};

export class SaveQuestionAnswerInput {
  answerId: Scalars['String'];
  questionId: Scalars['String'];
  quizId: Scalars['String'];
};

export class SearchBannerInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchCampaignInput {
  campaignId?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchContactInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['String']>;
};

export class SearchExtractCpfOrEmailInput {
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
};

export class SearchExtractUserInput {
  end?: InputMaybe<Scalars['DateTime']>;
  start?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
};

export class SearchFaqCategoryInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchFaqInput {
  categoryId?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchGoalInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  reference?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Array<Scalars['String']>>;
};

export class SearchHighlightInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchImportFileInput {
  end?: InputMaybe<Scalars['DateTime']>;
  start?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
};

export class SearchImportGoalInput {
  end?: InputMaybe<Scalars['DateTime']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
};

export class SearchImportRadarInput {
  end?: InputMaybe<Scalars['DateTime']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
};

export class SearchImportRankingInput {
  end?: InputMaybe<Scalars['DateTime']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Scalars['String']>;
};

export class SearchListResultInput {
  page?: InputMaybe<Scalars['Int']>;
  quizId?: InputMaybe<Scalars['String']>;
};

export class SearchMaterialInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchNarrativeInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchNotificationInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Array<Scalars['String']>>;
};

export class SearchPdvDistributionInput {
  campaignId?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  page?: InputMaybe<Scalars['Int']>;
  pdvId?: InputMaybe<Array<Scalars['String']>>;
  reference?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<Scalars['String']>>;
};

export class SearchPostCommentsInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<Scalars['String']>>;
};

export class SearchPostInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  postProfileId?: InputMaybe<Array<Scalars['String']>>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchPostProfileInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchQuizInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
  trainingClassId?: InputMaybe<Array<Scalars['String']>>;
};

export class SearchRadarInput {
  end?: InputMaybe<Scalars['DateTime']>;
  page?: InputMaybe<Scalars['Int']>;
  pdvId?: InputMaybe<Array<Scalars['String']>>;
  reference?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchRankingInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  reference?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  type?: InputMaybe<Array<Scalars['String']>>;
};

export class SearchRegulationInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchTrainingInput {
  end?: InputMaybe<Scalars['DateTime']>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class SearchUserInput {
  end?: InputMaybe<Scalars['DateTime']>;
  jobRoleId?: InputMaybe<Array<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pdvId?: InputMaybe<Array<Scalars['String']>>;
  profile?: InputMaybe<Array<Scalars['String']>>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<Scalars['String']>>;
};

export class SelfRegisterAddress {
  address: Scalars['String'];
  city: Scalars['String'];
  complement?: InputMaybe<Scalars['String']>;
  district: Scalars['String'];
  number: Scalars['String'];
  state: Scalars['String'];
  zipCode?: InputMaybe<Scalars['String']>;
};

export class SelfRegisterInput {
  accessTypeId: Scalars['String'];
  birthday: Scalars['DateTime'];
  cpf: Scalars['String'];
  email: Scalars['String'];
  group: Scalars['String'];
  hasPet: Scalars['Boolean'];
  hasPetType?: InputMaybe<Scalars['String']>;
  jobRoleId: Scalars['String'];
  name: Scalars['String'];
  nickName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  pdvId?: InputMaybe<Scalars['String']>;
  phone: Scalars['String'];
  userAddress: SelfRegisterAddress;
};

export class State {
  capital: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  region: Scalars['String'];
  state: Scalars['String'];
  uf: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export class Term {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  termAccept?: Maybe<Array<UserTermAccept>>;
  textAccept: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class Training {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
  trainingClass: Array<TrainingClass>;
  trainingRole: Array<TrainingRole>;
  updatedAt: Scalars['DateTime'];
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class TrainingClass {
  contentPdf?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  duration?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isContentConsumed: Scalars['Boolean'];
  isQuizDoneOrAbort: Scalars['Boolean'];
  materialUrl?: Maybe<Scalars['String']>;
  quiz?: Maybe<Array<Quiz>>;
  thumbnail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  training: Training;
  trainingClassUserAccess?: Maybe<Array<TrainingClassUserAccess>>;
  trainingContent?: Maybe<Array<TrainingContent>>;
  trainingMaterial?: Maybe<Array<TrainingMaterial>>;
  updatedAt: Scalars['DateTime'];
  videoOf?: Maybe<Scalars['String']>;
};

export class TrainingClassUserAccess {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  trainingClass: TrainingClass;
  user?: Maybe<User>;
};

export class TrainingContent {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  originalName?: Maybe<Scalars['String']>;
  trainingClass: TrainingClass;
  updatedAt: Scalars['DateTime'];
  urlMedia?: Maybe<Scalars['String']>;
};

export class TrainingMaterial {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  originalName?: Maybe<Scalars['String']>;
  trainingClass: TrainingClass;
  updatedAt: Scalars['DateTime'];
  urlMedia?: Maybe<Scalars['String']>;
};

export class TrainingPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<Training>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class TrainingRole {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  jobRole: JobRole;
  training: Training;
  updatedAt: Scalars['DateTime'];
};

export class UpdateAnswerInput {
  id: Scalars['String'];
  isCorrect?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Float']>;
  text?: InputMaybe<Scalars['String']>;
};

export class UpdateBannerInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateCampaignInput {
  award?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  mechanic?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  objectives?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class UpdateContactInput {
  answer: Scalars['String'];
  id: Scalars['String'];
};

export class UpdateContactTypeInput {
  id: Scalars['String'];
  name: Scalars['String'];
};

export class UpdateFaqCategoryInput {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export class UpdateFaqInput {
  answer?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  question?: InputMaybe<Scalars['String']>;
};

export class UpdateHighlightInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateMaterialInput {
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export class UpdateNarrativeInput {
  channel?: InputMaybe<Array<CreateChannelInput>>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export class UpdateNotificationInput {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAutomatic?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export class UpdatePostInput {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  postProfileId?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  urlVideo?: InputMaybe<Scalars['String']>;
};

export class UpdatePostProfileInput {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export class UpdateQuestionInput {
  answers: Array<UpdateAnswerInput>;
  id: Scalars['String'];
  points?: InputMaybe<Scalars['Float']>;
  questionNumber?: InputMaybe<Scalars['Float']>;
  text?: InputMaybe<Scalars['String']>;
};

export class UpdateQuizInput {
  end?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  questions: Array<UpdateQuestionInput>;
  start?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  trainingClassId?: InputMaybe<Scalars['String']>;
};

export class UpdateRegulationInput {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  textAccept?: InputMaybe<Scalars['String']>;
};

export class UpdateStatusCommentInput {
  commentId: Scalars['String'];
  /** ENUM approved | reproved */
  status: Scalars['String'];
};

export class UpdateTermInput {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  textAccept?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export class UpdateTrainingClassInput {
  /** video | pdf | podcast */
  contentType?: InputMaybe<Scalars['String']>;
  /** only if video or podcast */
  contentUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  /** only if video or podcast */
  trainingId?: InputMaybe<Scalars['String']>;
};

export class UpdateTrainingInput {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  trainingRoleIds?: InputMaybe<Array<Scalars['String']>>;
};

export class UpdateUserAddress {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  complement?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export class UpdateUserInput {
  accessTypeId?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Scalars['String']>;
  hasPet?: InputMaybe<Scalars['Boolean']>;
  hasPetType?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  jobRoleId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nickName?: InputMaybe<Scalars['String']>;
  pdvId?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subGroup?: InputMaybe<Scalars['String']>;
  userAddress?: InputMaybe<UpdateUserAddress>;
};

export class UpdateUserProfileAddress {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  complement?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export class UpdateUserProfileInput {
  birthday?: InputMaybe<Scalars['DateTime']>;
  cpf?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  jobRole?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nickName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profile?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  userAddress?: InputMaybe<UpdateUserProfileAddress>;
};

export class User {
  access?: Maybe<Array<UserAccess>>;
  accessType?: Maybe<AccessType>;
  account?: Maybe<Array<Account>>;
  address?: Maybe<Array<UserAddress>>;
  approvedSelfRegisterAt?: Maybe<Scalars['DateTime']>;
  birthday?: Maybe<Scalars['DateTime']>;
  codeFirsAccessExpiration?: Maybe<Scalars['DateTime']>;
  cpf?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstAccess: Scalars['Boolean'];
  firstAccessAt?: Maybe<Scalars['DateTime']>;
  goal?: Maybe<Array<Goal>>;
  group?: Maybe<Scalars['String']>;
  hasPet: Scalars['Boolean'];
  hasPetType?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobRole?: Maybe<JobRole>;
  name: Scalars['String'];
  nickName?: Maybe<Scalars['String']>;
  notificationUser?: Maybe<Array<NotificationUser>>;
  pdv?: Maybe<Pdv>;
  pdvPointDistributionUser?: Maybe<Array<PdvPointDistributionUser>>;
  phone?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  postLike?: Maybe<Array<PostLike>>;
  profile: Scalars['String'];
  ranking?: Maybe<Array<Ranking>>;
  regulationAccept?: Maybe<Array<UserRegulationAccept>>;
  reprovedReasonSelfRegister?: Maybe<Scalars['String']>;
  reprovedSelfRegisterAt?: Maybe<Scalars['DateTime']>;
  resetPasswordExpiration?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  subGroup?: Maybe<Scalars['String']>;
  trainingClassUserAccess?: Maybe<Array<TrainingClassUserAccess>>;
  updatedAt: Scalars['DateTime'];
  userApprovedSelfRegister?: Maybe<User>;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userPdv?: Maybe<Array<UserPdv>>;
  userReprovedSelfRegister?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class UserAccess {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ip: Scalars['String'];
  user: User;
};

export class UserAddress {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  district?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  number?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userUpdated?: Maybe<User>;
  zipCode?: Maybe<Scalars['String']>;
};

export class UserNotificationDto {
  campaignNotifications?: Maybe<Array<NotificationUserDto>>;
  postNotifications?: Maybe<Array<NotificationUserDto>>;
  total: Scalars['Int'];
  totalCampaign: Scalars['Int'];
  totalCampaignUnread: Scalars['Int'];
  totalPost: Scalars['Int'];
  totalPostUnread: Scalars['Int'];
  totalUnread: Scalars['Int'];
  totalWarning: Scalars['Int'];
  totalWarningUnread: Scalars['Int'];
  warningNotifications?: Maybe<Array<NotificationUserDto>>;
};

export class UserPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<User>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserPdv {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  pdv: Pdv;
  reference: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userCreated?: Maybe<User>;
  userDeleted?: Maybe<User>;
  userUpdated?: Maybe<User>;
};

export class UserPdvPage {
  currentPage: Scalars['Int'];
  nextPage: Scalars['Boolean'];
  pageSize: Scalars['Int'];
  previousPage: Scalars['Boolean'];
  results?: Maybe<Array<UserPdv>>;
  totalCount: Scalars['Int'];
  totalPage: Scalars['Int'];
};

export class UserRegulationAccept {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  regulation: Regulation;
  updatedAt: Scalars['DateTime'];
  user: User;
  userCreated?: Maybe<User>;
};

export class UserTermAccept {
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  term: Term;
  updatedAt: Scalars['DateTime'];
  user: User;
  userCreated?: Maybe<User>;
};

export class VerifyAcceptRegulationResult {
  accepted: Scalars['Boolean'];
  regulation: Regulation;
};

export class VerifyAcceptTermResult {
  accepted: Scalars['Boolean'];
  term: Term;
};

export type DefaultMessageFragment = { message: string, status: number };

export type UserSmallFragment = { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, photoUrl?: string | null, jobRole?: { id: string, name: string } | null };

export type UserFragment = { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, resetPasswordExpiration?: any | null, codeFirsAccessExpiration?: any | null, photoUrl?: string | null, status: string, firstAccess: boolean, firstAccessAt?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, group?: string | null, subGroup?: string | null, jobRole?: { id: string, name: string } | null, access?: Array<{ id: string }> | null, address?: Array<{ id: string, description: string, zipCode?: string | null, address?: string | null, number?: string | null, complement?: string | null, district?: string | null, city?: string | null, state?: string | null, country?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null, regulationAccept?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulation: { id: string, name: string, description: string, textAccept: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulationAccept?: Array<{ id: string }> | null } }> | null, postLike?: Array<{ id: string, post: { id: string } }> | null, accessType?: { id: string, name: string } | null, pdv?: { id: string, name?: string | null } | null };

export type AuthTypeFragment = { token: string, user: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, resetPasswordExpiration?: any | null, codeFirsAccessExpiration?: any | null, photoUrl?: string | null, status: string, firstAccess: boolean, firstAccessAt?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, group?: string | null, subGroup?: string | null, jobRole?: { id: string, name: string } | null, access?: Array<{ id: string }> | null, address?: Array<{ id: string, description: string, zipCode?: string | null, address?: string | null, number?: string | null, complement?: string | null, district?: string | null, city?: string | null, state?: string | null, country?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null, regulationAccept?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulation: { id: string, name: string, description: string, textAccept: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulationAccept?: Array<{ id: string }> | null } }> | null, postLike?: Array<{ id: string, post: { id: string } }> | null, accessType?: { id: string, name: string } | null, pdv?: { id: string, name?: string | null } | null }, regulations: Array<{ accepted: boolean, regulation: { id: string, name: string, textAccept: string, description: string } }>, terms: Array<{ accepted: boolean, term: { id: string, type: string, textAccept: string, description: string } }> };

export type BannerFragment = { id: string, title?: string | null, urlImage?: string | null, start?: any | null, link?: string | null, end?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, isActive: boolean };

export type HighlightFragment = { id: string, title: string, urlImage?: string | null, start: any, end: any, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, highlightStories?: Array<{ id: string, urlImage?: string | null }> | null };

export type HighlightStoryFragment = { id: string, urlImage?: string | null };

export type PostFragment = { id: string, qtdLike?: number | null, qtdComment?: number | null, title: string, description: string, type: string, urlImage?: string | null, urlVideo?: string | null, isActive: boolean, start?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, userLiked?: boolean | null, userCreated?: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, photoUrl?: string | null, jobRole?: { id: string, name: string } | null } | null, userUpdated?: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, photoUrl?: string | null, jobRole?: { id: string, name: string } | null } | null, userDeleted?: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, photoUrl?: string | null, jobRole?: { id: string, name: string } | null } | null, postProfile: { id: string, name: string, urlImage?: string | null }, postComment?: Array<{ id: string, comment: string, user: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, photoUrl?: string | null, jobRole?: { id: string, name: string } | null } }> | null };

export type CampaignFragment = { id: string, name: string, objectives: string, mechanic: string, award: string, urlImage?: string | null, start?: any | null, end?: any | null, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, urlFriendly?: string | null, campaignMaterial?: Array<{ id: string, urlMedia?: string | null, originalName?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null };

export type FaqFragment = { id: string, question: string, answer: string };

export type TrainingFragment = { id: string, title: string, description: string, createdAt: any, updatedAt: any, trainingRole: Array<{ id: string }>, trainingClass: Array<{ id: string, duration?: number | null, contentType?: string | null, contentPdf?: string | null, title: string, description: string, thumbnail?: string | null, videoOf?: string | null, contentUrl?: string | null, materialUrl?: string | null, isQuizDoneOrAbort: boolean, isContentConsumed: boolean, quiz?: Array<{ id: string, title: string }> | null }> };

export type QuizQuestionDtoFragment = { totalQuestions: number, currentQuestion: number, question: { id: string, text: string, questionNumber: number, points?: number | null, createdAt: any, updatedAt: any, deletedAt?: any | null, quizQuestionAnswer?: Array<{ id: string, isCorrectForAdmin?: boolean | null, text: string, ordination: number, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null }, answers: Array<{ id: string, text: string, ordination: number, isCorrectForAdmin?: boolean | null }> };

export type NotificationUserFragment = { id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, isRead: boolean, readAt?: any | null, notification: { id: string, title: string, description: string, type: string, isAutomatic: boolean, isActive: boolean, link?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null } };

export type UserNotificationDtoFragment = { total: number, totalUnread: number, totalCampaign: number, totalCampaignUnread: number, totalPost: number, totalPostUnread: number, totalWarning: number, totalWarningUnread: number, campaignNotifications?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, isRead: boolean, readAt?: any | null, notification: { id: string, title: string, description: string, type: string, isAutomatic: boolean, isActive: boolean, link?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, postNotifications?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, isRead: boolean, readAt?: any | null, notification: { id: string, title: string, description: string, type: string, isAutomatic: boolean, isActive: boolean, link?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, warningNotifications?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, isRead: boolean, readAt?: any | null, notification: { id: string, title: string, description: string, type: string, isAutomatic: boolean, isActive: boolean, link?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null };

export type NotificationFragment = { id: string, title: string, description: string, type: string, isAutomatic: boolean, isActive: boolean, link?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null };

export type RegulationFragment = { id: string, name: string, description: string, textAccept: string, createdAt: any, updatedAt: any, deletedAt?: any | null };

export type AccountExtractResponseFragment = { account?: { id: string, balance: number, createdAt: any, updatedAt: any } | null, extract?: Array<{ month: string, year: string, movements?: Array<{ id: string, balance: number, type: string, description: string, value: number, createdAt: any, updatedAt: any }> | null }> | null };

export type PdvPointFragment = { id: string, points: number, isActive: boolean, reference: string, isApproved?: boolean | null, userQtd: number, pdv: { id: string, name?: string | null }, campaign: { id: string, name: string, urlImage?: string | null, start?: any | null, end?: any | null } };

export type PdvPointDistributionFragment = { id: string, status: string, userQtd: number, reprovedReason?: string | null, userReproved?: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, resetPasswordExpiration?: any | null, codeFirsAccessExpiration?: any | null, photoUrl?: string | null, status: string, firstAccess: boolean, firstAccessAt?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, group?: string | null, subGroup?: string | null, jobRole?: { id: string, name: string } | null, access?: Array<{ id: string }> | null, address?: Array<{ id: string, description: string, zipCode?: string | null, address?: string | null, number?: string | null, complement?: string | null, district?: string | null, city?: string | null, state?: string | null, country?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null, regulationAccept?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulation: { id: string, name: string, description: string, textAccept: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulationAccept?: Array<{ id: string }> | null } }> | null, postLike?: Array<{ id: string, post: { id: string } }> | null, accessType?: { id: string, name: string } | null, pdv?: { id: string, name?: string | null } | null } | null, pdvPoint: { id: string, points: number, isActive: boolean, reference: string, isApproved?: boolean | null, userQtd: number, pdv: { id: string, name?: string | null }, campaign: { id: string, name: string, urlImage?: string | null, start?: any | null, end?: any | null } } };

export type RankingDtoFragment = { reference: string, updatedAt: any, monthly: { points: number, position: number }, trimester: { points: number, position: number }, annual: { points: number, position: number } };

export type RankingAllDtoFragment = { reference: string, monthly: Array<{ name: string, points: number, position: number }>, trimester: Array<{ name: string, points: number, position: number }>, annual: Array<{ name: string, points: number, position: number }> };

export type BannersQueryVariables = Exact<{ [key: string]: never; }>;


export type BannersQuery = { banners: Array<{ id: string, title?: string | null, urlImage?: string | null, start?: any | null, link?: string | null, end?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, isActive: boolean }> };

export type CampaignListQueryVariables = Exact<{ [key: string]: never; }>;


export type CampaignListQuery = { campaignList: Array<{ id: string, name: string, objectives: string, mechanic: string, award: string, urlImage?: string | null, start?: any | null, end?: any | null, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, urlFriendly?: string | null, campaignMaterial?: Array<{ id: string, urlMedia?: string | null, originalName?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null }> };

export type CampaignByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CampaignByIdQuery = { campaignById: { id: string, name: string, objectives: string, mechanic: string, award: string, urlImage?: string | null, start?: any | null, end?: any | null, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, urlFriendly?: string | null, campaignMaterial?: Array<{ id: string, urlMedia?: string | null, originalName?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null } };

export type CampaignByFriendlyUrlQueryVariables = Exact<{
  urlFriendly: Scalars['String'];
}>;


export type CampaignByFriendlyUrlQuery = { campaignByFriendlyUrl: { id: string, name: string, objectives: string, mechanic: string, award: string, urlImage?: string | null, start?: any | null, end?: any | null, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, urlFriendly?: string | null, campaignMaterial?: Array<{ id: string, urlMedia?: string | null, originalName?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null } };

export type LatestCampaignQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestCampaignQuery = { latestCampaign: { id: string, name: string, objectives: string, mechanic: string, award: string, urlImage?: string | null, start?: any | null, end?: any | null, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, urlFriendly?: string | null, campaignMaterial?: Array<{ id: string, urlMedia?: string | null, originalName?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null } };

export type FaqListQueryVariables = Exact<{ [key: string]: never; }>;


export type FaqListQuery = { faqList: Array<{ id: string, question: string, answer: string }> };

export type RegulationListQueryVariables = Exact<{ [key: string]: never; }>;


export type RegulationListQuery = { regulationList: Array<{ id: string, name: string, description: string, textAccept: string, createdAt: any, updatedAt: any, deletedAt?: any | null }> };

export type ExtractByUserQueryVariables = Exact<{
  input: SearchExtractUserInput;
}>;


export type ExtractByUserQuery = { extractByUser?: { account?: { id: string, balance: number, createdAt: any, updatedAt: any } | null, extract?: Array<{ month: string, year: string, movements?: Array<{ id: string, balance: number, type: string, description: string, value: number, createdAt: any, updatedAt: any }> | null }> | null } | null };

export type AccountByUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountByUserQuery = { accountByUser?: { id: string, balance: number } | null };

export type ContactTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactTypesQuery = { contactTypes: Array<{ id: string, name: string }> };

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;


export type CreateContactMutation = { createContact: { id: string, name: string, email: string, description: string, answer?: string | null, status: string, createdAt: any, updatedAt: any, type?: { id: string, name: string } | null } };

export type ListPdvPointForDistributionQueryVariables = Exact<{
  data: SearchPdvDistributionInput;
}>;


export type ListPdvPointForDistributionQuery = { listPdvPointForDistribution: Array<{ id: string, points: number, isActive: boolean, reference: string, isApproved?: boolean | null, userQtd: number, pdv: { id: string, name?: string | null }, campaign: { id: string, name: string, urlImage?: string | null, start?: any | null, end?: any | null } }> };

export type ListPdvPointsDistributedQueryVariables = Exact<{
  data: SearchPdvDistributionInput;
}>;


export type ListPdvPointsDistributedQuery = { listPdvPointsDistributed: Array<{ id: string, status: string, userQtd: number, reprovedReason?: string | null, userReproved?: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, resetPasswordExpiration?: any | null, codeFirsAccessExpiration?: any | null, photoUrl?: string | null, status: string, firstAccess: boolean, firstAccessAt?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, group?: string | null, subGroup?: string | null, jobRole?: { id: string, name: string } | null, access?: Array<{ id: string }> | null, address?: Array<{ id: string, description: string, zipCode?: string | null, address?: string | null, number?: string | null, complement?: string | null, district?: string | null, city?: string | null, state?: string | null, country?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null, regulationAccept?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulation: { id: string, name: string, description: string, textAccept: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulationAccept?: Array<{ id: string }> | null } }> | null, postLike?: Array<{ id: string, post: { id: string } }> | null, accessType?: { id: string, name: string } | null, pdv?: { id: string, name?: string | null } | null } | null, pdvPoint: { id: string, points: number, isActive: boolean, reference: string, isApproved?: boolean | null, userQtd: number, pdv: { id: string, name?: string | null }, campaign: { id: string, name: string, urlImage?: string | null, start?: any | null, end?: any | null } } }> };

export type UsersByPdvIdQueryVariables = Exact<{
  keyword?: InputMaybe<Scalars['String']>;
  pdvId: Scalars['String'];
}>;


export type UsersByPdvIdQuery = { usersByPdvId: Array<{ id: string, name: string, photoUrl?: string | null }> };

export type PdvPointsByIdQueryVariables = Exact<{
  pdvPointId: Scalars['String'];
}>;


export type PdvPointsByIdQuery = { pdvPointsById: { id: string, points: number, isActive: boolean, reference: string, isApproved?: boolean | null, userQtd: number, pdv: { id: string, name?: string | null }, campaign: { id: string, name: string, urlImage?: string | null, start?: any | null, end?: any | null } } };

export type CreateMultipleDistributionForUserMutationVariables = Exact<{
  data: CreateMultipleDistributionUser;
}>;


export type CreateMultipleDistributionForUserMutation = { createMultipleDistributionForUser: { message: string, status: number } };

export type HighlightsQueryVariables = Exact<{ [key: string]: never; }>;


export type HighlightsQuery = { highlights: Array<{ id: string, title: string, urlImage?: string | null, start: any, end: any, isActive: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null, highlightStories?: Array<{ id: string, urlImage?: string | null }> | null }> };

export type HighlightStoryQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type HighlightStoryQuery = { highlightStory: { id: string, urlImage?: string | null } };

export type ListGoalUserVariavelQueryVariables = Exact<{
  reference: Scalars['String'];
}>;


export type ListGoalUserVariavelQuery = { listGoalUserVariavel?: { reference: string, goal?: Array<{ goal: number, description: string, percent: number, result: number }> | null } | null };

export type ListGoalUserFixoQueryVariables = Exact<{
  reference: Scalars['String'];
}>;


export type ListGoalUserFixoQuery = { listGoalUserFixo?: { reference: string, goal?: Array<{ goal: number, description: string, percent: number, result: number }> | null } | null };

export type ListGoalPdvVariavelQueryVariables = Exact<{
  reference: Scalars['String'];
}>;


export type ListGoalPdvVariavelQuery = { listGoalPdvVariavel?: { reference: string, goal?: Array<{ goal: number, description: string, percent: number, result: number }> | null } | null };

export type ListGoalPdvFixoQueryVariables = Exact<{
  reference: Scalars['String'];
}>;


export type ListGoalPdvFixoQuery = { listGoalPdvFixo?: { reference: string, goal?: Array<{ goal: number, description: string, percent: number, result: number }> | null } | null };

export type PostListQueryVariables = Exact<{ [key: string]: never; }>;


export type PostListQuery = { postList: Array<{ id: string, qtdLike?: number | null, qtdComment?: number | null, title: string, description: string, type: string, urlImage?: string | null, urlVideo?: string | null, isActive: boolean, start?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, userLiked?: boolean | null, userCreated?: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, photoUrl?: string | null, jobRole?: { id: string, name: string } | null } | null, userUpdated?: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, photoUrl?: string | null, jobRole?: { id: string, name: string } | null } | null, userDeleted?: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, photoUrl?: string | null, jobRole?: { id: string, name: string } | null } | null, postProfile: { id: string, name: string, urlImage?: string | null }, postComment?: Array<{ id: string, comment: string, user: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, photoUrl?: string | null, jobRole?: { id: string, name: string } | null } }> | null }> };

export type PostCommentsApprovedByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PostCommentsApprovedByIdQuery = { postCommentsApprovedById: { id: string, qtdLike?: number | null, qtdComment?: number | null, title: string, description: string, type: string, urlImage?: string | null, urlVideo?: string | null, isActive: boolean, start?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, userLiked?: boolean | null, userCreated?: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, photoUrl?: string | null, jobRole?: { id: string, name: string } | null } | null, userUpdated?: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, photoUrl?: string | null, jobRole?: { id: string, name: string } | null } | null, userDeleted?: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, photoUrl?: string | null, jobRole?: { id: string, name: string } | null } | null, postProfile: { id: string, name: string, urlImage?: string | null }, postComment?: Array<{ id: string, comment: string, user: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, photoUrl?: string | null, jobRole?: { id: string, name: string } | null } }> | null } };

export type CreateUserPostCommentMutationVariables = Exact<{
  input: CreateUserPostCommentInput;
}>;


export type CreateUserPostCommentMutation = { createUserPostComment: { message: string, status: number } };

export type CreateUserPostLikeMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type CreateUserPostLikeMutation = { createUserPostLike: { message: string, status: number } };

export type NotificationsByUserQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsByUserQuery = { notificationsByUser: { total: number, totalUnread: number, totalCampaign: number, totalCampaignUnread: number, totalPost: number, totalPostUnread: number, totalWarning: number, totalWarningUnread: number, campaignNotifications?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, isRead: boolean, readAt?: any | null, notification: { id: string, title: string, description: string, type: string, isAutomatic: boolean, isActive: boolean, link?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, postNotifications?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, isRead: boolean, readAt?: any | null, notification: { id: string, title: string, description: string, type: string, isAutomatic: boolean, isActive: boolean, link?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null, warningNotifications?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, isRead: boolean, readAt?: any | null, notification: { id: string, title: string, description: string, type: string, isAutomatic: boolean, isActive: boolean, link?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null } }> | null } };

export type ReadNotificationMutationVariables = Exact<{
  userNotificationId: Scalars['String'];
}>;


export type ReadNotificationMutation = { readNotification: { message: string, status: number } };

export type ReadAllNotificationMutationVariables = Exact<{ [key: string]: never; }>;


export type ReadAllNotificationMutation = { readAllNotifications: { message: string, status: number } };

export type DeleteAllNotificationsMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAllNotificationsMutation = { deleteAllNotifications: { message: string, status: number } };

export type QuestionByQuizIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type QuestionByQuizIdQuery = { questionByQuizId: { totalQuestions: number, currentQuestion: number, question: { id: string, text: string, questionNumber: number, points?: number | null, createdAt: any, updatedAt: any, deletedAt?: any | null, quizQuestionAnswer?: Array<{ id: string, isCorrectForAdmin?: boolean | null, text: string, ordination: number, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null }, answers: Array<{ id: string, text: string, ordination: number, isCorrectForAdmin?: boolean | null }> } };

export type SaveQuestionAnswerMutationVariables = Exact<{
  input: SaveQuestionAnswerInput;
}>;


export type SaveQuestionAnswerMutation = { saveQuestionAnswer: { message: string, status: number } };

export type AbortQuizMutationVariables = Exact<{
  quizId: Scalars['String'];
}>;


export type AbortQuizMutation = { abortQuiz: { message: string, status: number } };

export type FinishQuizMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type FinishQuizMutation = { finishQuiz: { totalQuestions: number, correctAnswers: number, totalPoints: number, percentageCorrect: number, startedIn?: any | null, finishedIn?: any | null, status?: string | null } };

export type ConsumeContentMutationVariables = Exact<{
  trainingClassId: Scalars['String'];
}>;


export type ConsumeContentMutation = { consumeContent: { message: string, status: number } };

export type RankingByUserQueryVariables = Exact<{
  reference: Scalars['String'];
}>;


export type RankingByUserQuery = { rankingByUser?: { reference: string, updatedAt: any, monthly: { points: number, position: number }, trimester: { points: number, position: number }, annual: { points: number, position: number } } | null };

export type RankingByPdvQueryVariables = Exact<{
  reference: Scalars['String'];
}>;


export type RankingByPdvQuery = { rankingByPdv?: { reference: string, updatedAt: any, monthly: { points: number, position: number }, trimester: { points: number, position: number }, annual: { points: number, position: number } } | null };

export type RankingAllUserQueryVariables = Exact<{
  reference: Scalars['String'];
}>;


export type RankingAllUserQuery = { rankingAllUser?: { reference: string, monthly: Array<{ name: string, points: number, position: number }>, trimester: Array<{ name: string, points: number, position: number }>, annual: Array<{ name: string, points: number, position: number }> } | null };

export type RankingAllPdvQueryVariables = Exact<{
  reference: Scalars['String'];
}>;


export type RankingAllPdvQuery = { rankingAllPdv?: { reference: string, monthly: Array<{ name: string, points: number, position: number }>, trimester: Array<{ name: string, points: number, position: number }>, annual: Array<{ name: string, points: number, position: number }> } | null };

export type ValidateCpfQueryVariables = Exact<{
  cpf: Scalars['String'];
}>;


export type ValidateCpfQuery = { validateCPF: boolean };

export type ValidateEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type ValidateEmailQuery = { validateEmail: boolean };

export type ListPdvQueryVariables = Exact<{ [key: string]: never; }>;


export type ListPdvQuery = { listPdv: Array<{ id: string, name?: string | null }> };

export type ListJobRoleQueryVariables = Exact<{ [key: string]: never; }>;


export type ListJobRoleQuery = { listJobRole: Array<{ id: string, name: string }> };

export type ListAccessTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAccessTypeQuery = { listAccessType: Array<{ id: string, name: string }> };

export type SelfRegisterMutationVariables = Exact<{
  data: SelfRegisterInput;
}>;


export type SelfRegisterMutation = { selfRegister: { message: string, status: number } };

export type AcceptRegulationMutationVariables = Exact<{
  data: Scalars['String'];
}>;


export type AcceptRegulationMutation = { acceptRegulation: { message: string, status: number } };

export type AcceptTermMutationVariables = Exact<{
  data: Scalars['String'];
}>;


export type AcceptTermMutation = { acceptTerm: { message: string, status: number } };

export type TrainingsQueryVariables = Exact<{ [key: string]: never; }>;


export type TrainingsQuery = { trainings: Array<{ id: string, title: string }> };

export type TrainingQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TrainingQuery = { training: { id: string, title: string, description: string, createdAt: any, updatedAt: any, trainingRole: Array<{ id: string }>, trainingClass: Array<{ id: string, duration?: number | null, contentType?: string | null, contentPdf?: string | null, title: string, description: string, thumbnail?: string | null, videoOf?: string | null, contentUrl?: string | null, materialUrl?: string | null, isQuizDoneOrAbort: boolean, isContentConsumed: boolean, quiz?: Array<{ id: string, title: string }> | null }> } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { currentUser: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, resetPasswordExpiration?: any | null, codeFirsAccessExpiration?: any | null, photoUrl?: string | null, status: string, firstAccess: boolean, firstAccessAt?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, group?: string | null, subGroup?: string | null, jobRole?: { id: string, name: string } | null, access?: Array<{ id: string }> | null, address?: Array<{ id: string, description: string, zipCode?: string | null, address?: string | null, number?: string | null, complement?: string | null, district?: string | null, city?: string | null, state?: string | null, country?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null, regulationAccept?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulation: { id: string, name: string, description: string, textAccept: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulationAccept?: Array<{ id: string }> | null } }> | null, postLike?: Array<{ id: string, post: { id: string } }> | null, accessType?: { id: string, name: string } | null, pdv?: { id: string, name?: string | null } | null } };

export type EnumByTypeQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type EnumByTypeQuery = { enumByType: Array<{ id: string, type: string, description?: string | null, value: string, detail?: string | null }> };

export type UpdateUserProfileMutationVariables = Exact<{
  data: UpdateUserProfileInput;
}>;


export type UpdateUserProfileMutation = { updateUserProfile: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, resetPasswordExpiration?: any | null, codeFirsAccessExpiration?: any | null, photoUrl?: string | null, status: string, firstAccess: boolean, firstAccessAt?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, group?: string | null, subGroup?: string | null, jobRole?: { id: string, name: string } | null, access?: Array<{ id: string }> | null, address?: Array<{ id: string, description: string, zipCode?: string | null, address?: string | null, number?: string | null, complement?: string | null, district?: string | null, city?: string | null, state?: string | null, country?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null, regulationAccept?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulation: { id: string, name: string, description: string, textAccept: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulationAccept?: Array<{ id: string }> | null } }> | null, postLike?: Array<{ id: string, post: { id: string } }> | null, accessType?: { id: string, name: string } | null, pdv?: { id: string, name?: string | null } | null } };

export type LoginMutationVariables = Exact<{
  data: AuthInput;
}>;


export type LoginMutation = { login: { token: string, user: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, resetPasswordExpiration?: any | null, codeFirsAccessExpiration?: any | null, photoUrl?: string | null, status: string, firstAccess: boolean, firstAccessAt?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, group?: string | null, subGroup?: string | null, jobRole?: { id: string, name: string } | null, access?: Array<{ id: string }> | null, address?: Array<{ id: string, description: string, zipCode?: string | null, address?: string | null, number?: string | null, complement?: string | null, district?: string | null, city?: string | null, state?: string | null, country?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null, regulationAccept?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulation: { id: string, name: string, description: string, textAccept: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulationAccept?: Array<{ id: string }> | null } }> | null, postLike?: Array<{ id: string, post: { id: string } }> | null, accessType?: { id: string, name: string } | null, pdv?: { id: string, name?: string | null } | null }, regulations: Array<{ accepted: boolean, regulation: { id: string, name: string, textAccept: string, description: string } }>, terms: Array<{ accepted: boolean, term: { id: string, type: string, textAccept: string, description: string } }> } };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  type: Scalars['String'];
}>;


export type ForgotPasswordMutation = { forgotPassword: { message: string, status: number } };

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { resetPassword: { message: string, status: number } };

export type ChangePasswordMutationVariables = Exact<{
  data: ChangePasswordInput;
}>;


export type ChangePasswordMutation = { changePassword: { message: string, status: number } };

export type FirstAccessMutationVariables = Exact<{
  data: FirstAccessInput;
}>;


export type FirstAccessMutation = { firstAccess: { message: string, status: number } };

export type FirstAccessValidationCodeMutationVariables = Exact<{
  data: FirstAccessValidationCodeInput;
}>;


export type FirstAccessValidationCodeMutation = { firstAccessValidationCode: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, resetPasswordExpiration?: any | null, codeFirsAccessExpiration?: any | null, photoUrl?: string | null, status: string, firstAccess: boolean, firstAccessAt?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, group?: string | null, subGroup?: string | null, jobRole?: { id: string, name: string } | null, access?: Array<{ id: string }> | null, address?: Array<{ id: string, description: string, zipCode?: string | null, address?: string | null, number?: string | null, complement?: string | null, district?: string | null, city?: string | null, state?: string | null, country?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null, regulationAccept?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulation: { id: string, name: string, description: string, textAccept: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulationAccept?: Array<{ id: string }> | null } }> | null, postLike?: Array<{ id: string, post: { id: string } }> | null, accessType?: { id: string, name: string } | null, pdv?: { id: string, name?: string | null } | null } };

export type FirstAccessRegisterUserMutationVariables = Exact<{
  data: FirstAccessRegisterUserInput;
}>;


export type FirstAccessRegisterUserMutation = { firstAccessRegisterUser: { id: string, name: string, nickName?: string | null, cpf?: string | null, email: string, birthday?: any | null, phone?: string | null, profile: string, resetPasswordExpiration?: any | null, codeFirsAccessExpiration?: any | null, photoUrl?: string | null, status: string, firstAccess: boolean, firstAccessAt?: any | null, createdAt: any, updatedAt: any, deletedAt?: any | null, group?: string | null, subGroup?: string | null, jobRole?: { id: string, name: string } | null, access?: Array<{ id: string }> | null, address?: Array<{ id: string, description: string, zipCode?: string | null, address?: string | null, number?: string | null, complement?: string | null, district?: string | null, city?: string | null, state?: string | null, country?: string | null, createdAt: any, updatedAt: any, deletedAt?: any | null }> | null, regulationAccept?: Array<{ id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulation: { id: string, name: string, description: string, textAccept: string, createdAt: any, updatedAt: any, deletedAt?: any | null, regulationAccept?: Array<{ id: string }> | null } }> | null, postLike?: Array<{ id: string, post: { id: string } }> | null, accessType?: { id: string, name: string } | null, pdv?: { id: string, name?: string | null } | null } };

export const DefaultMessageFragmentDoc = gql`
    fragment DefaultMessage on DefaultMessage {
  message
  status
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  nickName
  cpf
  email
  birthday
  phone
  jobRole {
    id
    name
  }
  profile
  resetPasswordExpiration
  codeFirsAccessExpiration
  photoUrl
  status
  firstAccess
  firstAccessAt
  createdAt
  updatedAt
  deletedAt
  access {
    id
  }
  address {
    id
    description
    zipCode
    address
    number
    complement
    district
    city
    state
    country
    createdAt
    updatedAt
    deletedAt
  }
  regulationAccept {
    id
    createdAt
    updatedAt
    deletedAt
    regulation {
      id
      name
      description
      textAccept
      createdAt
      updatedAt
      deletedAt
      regulationAccept {
        id
      }
    }
  }
  postLike {
    id
    post {
      id
    }
  }
  accessType {
    id
    name
  }
  pdv {
    id
    name
  }
  group
  subGroup
}
    `;
export const AuthTypeFragmentDoc = gql`
    fragment AuthType on AuthType {
  user {
    ...User
  }
  token
  regulations {
    accepted
    regulation {
      id
      name
      textAccept
      description
    }
  }
  terms {
    accepted
    term {
      id
      type
      textAccept
      description
    }
  }
}
    ${UserFragmentDoc}`;
export const BannerFragmentDoc = gql`
    fragment Banner on Banner {
  id
  title
  urlImage
  start
  link
  end
  createdAt
  updatedAt
  deletedAt
  isActive
}
    `;
export const HighlightStoryFragmentDoc = gql`
    fragment HighlightStory on HighlightStory {
  id
  urlImage
}
    `;
export const HighlightFragmentDoc = gql`
    fragment Highlight on Highlight {
  id
  title
  urlImage
  start
  end
  isActive
  createdAt
  updatedAt
  deletedAt
  highlightStories {
    ...HighlightStory
  }
}
    ${HighlightStoryFragmentDoc}`;
export const UserSmallFragmentDoc = gql`
    fragment UserSmall on User {
  id
  name
  nickName
  cpf
  email
  birthday
  phone
  jobRole {
    id
    name
  }
  profile
  photoUrl
}
    `;
export const PostFragmentDoc = gql`
    fragment Post on Post {
  id
  qtdLike
  qtdComment
  title
  description
  type
  urlImage
  urlVideo
  isActive
  start
  createdAt
  updatedAt
  deletedAt
  userLiked
  userCreated {
    ...UserSmall
  }
  userUpdated {
    ...UserSmall
  }
  userDeleted {
    ...UserSmall
  }
  postProfile {
    id
    name
    urlImage
  }
  postComment {
    id
    comment
    user {
      ...UserSmall
    }
  }
}
    ${UserSmallFragmentDoc}`;
export const CampaignFragmentDoc = gql`
    fragment Campaign on Campaign {
  id
  name
  objectives
  mechanic
  award
  urlImage
  start
  end
  isActive
  createdAt
  updatedAt
  deletedAt
  urlFriendly
  campaignMaterial {
    id
    urlMedia
    originalName
    createdAt
    updatedAt
    deletedAt
  }
}
    `;
export const FaqFragmentDoc = gql`
    fragment Faq on Faq {
  id
  question
  answer
}
    `;
export const TrainingFragmentDoc = gql`
    fragment Training on Training {
  id
  title
  description
  trainingRole {
    id
  }
  trainingClass {
    id
    duration
    contentType
    contentPdf
    title
    description
    thumbnail
    videoOf
    contentUrl
    materialUrl
    quiz {
      id
      title
    }
    isQuizDoneOrAbort
    isContentConsumed
  }
  createdAt
  updatedAt
}
    `;
export const QuizQuestionDtoFragmentDoc = gql`
    fragment QuizQuestionDTO on QuizQuestionDTO {
  totalQuestions
  currentQuestion
  question {
    id
    text
    questionNumber
    points
    createdAt
    updatedAt
    deletedAt
    quizQuestionAnswer {
      id
      isCorrectForAdmin
      text
      ordination
      createdAt
      updatedAt
      deletedAt
    }
  }
  answers {
    id
    text
    ordination
    isCorrectForAdmin
  }
}
    `;
export const NotificationFragmentDoc = gql`
    fragment Notification on NotificationDTO {
  id
  title
  description
  type
  isAutomatic
  isActive
  link
  createdAt
  updatedAt
  deletedAt
}
    `;
export const NotificationUserFragmentDoc = gql`
    fragment NotificationUser on NotificationUserDTO {
  id
  createdAt
  updatedAt
  deletedAt
  isRead
  readAt
  notification {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;
export const UserNotificationDtoFragmentDoc = gql`
    fragment UserNotificationDto on UserNotificationDto {
  total
  totalUnread
  totalCampaign
  totalCampaignUnread
  campaignNotifications {
    ...NotificationUser
  }
  totalPost
  totalPostUnread
  postNotifications {
    ...NotificationUser
  }
  totalWarning
  totalWarningUnread
  warningNotifications {
    ...NotificationUser
  }
}
    ${NotificationUserFragmentDoc}`;
export const RegulationFragmentDoc = gql`
    fragment Regulation on Regulation {
  id
  name
  description
  textAccept
  createdAt
  updatedAt
  deletedAt
}
    `;
export const AccountExtractResponseFragmentDoc = gql`
    fragment AccountExtractResponse on AccountExtractResponse {
  account {
    id
    balance
    createdAt
    updatedAt
  }
  extract {
    month
    year
    movements {
      id
      balance
      type
      description
      value
      createdAt
      updatedAt
    }
  }
}
    `;
export const PdvPointFragmentDoc = gql`
    fragment PdvPoint on PdvPoint {
  id
  points
  isActive
  reference
  isApproved
  userQtd
  pdv {
    id
    name
  }
  campaign {
    id
    name
    urlImage
    start
    end
  }
}
    `;
export const PdvPointDistributionFragmentDoc = gql`
    fragment PdvPointDistribution on PdvPointDistribution {
  id
  status
  userQtd
  reprovedReason
  userReproved {
    ...User
  }
  pdvPoint {
    ...PdvPoint
  }
}
    ${UserFragmentDoc}
${PdvPointFragmentDoc}`;
export const RankingDtoFragmentDoc = gql`
    fragment RankingDTO on RankingDTO {
  monthly {
    points
    position
  }
  trimester {
    points
    position
  }
  annual {
    points
    position
  }
  reference
  updatedAt
}
    `;
export const RankingAllDtoFragmentDoc = gql`
    fragment RankingAllDTO on RankingAllDTO {
  monthly {
    name
    points
    position
  }
  trimester {
    name
    points
    position
  }
  annual {
    name
    points
    position
  }
  reference
}
    `;
export const BannersDocument = gql`
    query banners {
  banners {
    ...Banner
  }
}
    ${BannerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BannersGQL extends Apollo.Query<BannersQuery, BannersQueryVariables> {
    document = BannersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CampaignListDocument = gql`
    query campaignList {
  campaignList {
    ...Campaign
  }
}
    ${CampaignFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CampaignListGQL extends Apollo.Query<CampaignListQuery, CampaignListQueryVariables> {
    document = CampaignListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CampaignByIdDocument = gql`
    query campaignById($id: String!) {
  campaignById(id: $id) {
    ...Campaign
  }
}
    ${CampaignFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CampaignByIdGQL extends Apollo.Query<CampaignByIdQuery, CampaignByIdQueryVariables> {
    document = CampaignByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CampaignByFriendlyUrlDocument = gql`
    query campaignByFriendlyUrl($urlFriendly: String!) {
  campaignByFriendlyUrl(urlFriendly: $urlFriendly) {
    ...Campaign
  }
}
    ${CampaignFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CampaignByFriendlyUrlGQL extends Apollo.Query<CampaignByFriendlyUrlQuery, CampaignByFriendlyUrlQueryVariables> {
    document = CampaignByFriendlyUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LatestCampaignDocument = gql`
    query latestCampaign {
  latestCampaign {
    ...Campaign
  }
}
    ${CampaignFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LatestCampaignGQL extends Apollo.Query<LatestCampaignQuery, LatestCampaignQueryVariables> {
    document = LatestCampaignDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FaqListDocument = gql`
    query faqList {
  faqList {
    ...Faq
  }
}
    ${FaqFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FaqListGQL extends Apollo.Query<FaqListQuery, FaqListQueryVariables> {
    document = FaqListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RegulationListDocument = gql`
    query regulationList {
  regulationList {
    ...Regulation
  }
}
    ${RegulationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RegulationListGQL extends Apollo.Query<RegulationListQuery, RegulationListQueryVariables> {
    document = RegulationListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExtractByUserDocument = gql`
    query extractByUser($input: SearchExtractUserInput!) {
  extractByUser(data: $input) {
    ...AccountExtractResponse
  }
}
    ${AccountExtractResponseFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ExtractByUserGQL extends Apollo.Query<ExtractByUserQuery, ExtractByUserQueryVariables> {
    document = ExtractByUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountByUserDocument = gql`
    query accountByUser {
  accountByUser {
    id
    balance
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountByUserGQL extends Apollo.Query<AccountByUserQuery, AccountByUserQueryVariables> {
    document = AccountByUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContactTypesDocument = gql`
    query contactTypes {
  contactTypes {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactTypesGQL extends Apollo.Query<ContactTypesQuery, ContactTypesQueryVariables> {
    document = ContactTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateContactDocument = gql`
    mutation createContact($input: CreateContactInput!) {
  createContact(data: $input) {
    id
    name
    email
    description
    answer
    status
    type {
      id
      name
    }
    createdAt
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateContactGQL extends Apollo.Mutation<CreateContactMutation, CreateContactMutationVariables> {
    document = CreateContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListPdvPointForDistributionDocument = gql`
    query ListPdvPointForDistribution($data: SearchPdvDistributionInput!) {
  listPdvPointForDistribution(data: $data) {
    ...PdvPoint
  }
}
    ${PdvPointFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListPdvPointForDistributionGQL extends Apollo.Query<ListPdvPointForDistributionQuery, ListPdvPointForDistributionQueryVariables> {
    document = ListPdvPointForDistributionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListPdvPointsDistributedDocument = gql`
    query ListPdvPointsDistributed($data: SearchPdvDistributionInput!) {
  listPdvPointsDistributed(data: $data) {
    ...PdvPointDistribution
  }
}
    ${PdvPointDistributionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListPdvPointsDistributedGQL extends Apollo.Query<ListPdvPointsDistributedQuery, ListPdvPointsDistributedQueryVariables> {
    document = ListPdvPointsDistributedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersByPdvIdDocument = gql`
    query UsersByPdvId($keyword: String, $pdvId: String!) {
  usersByPdvId(keyword: $keyword, pdvId: $pdvId) {
    id
    name
    photoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersByPdvIdGQL extends Apollo.Query<UsersByPdvIdQuery, UsersByPdvIdQueryVariables> {
    document = UsersByPdvIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PdvPointsByIdDocument = gql`
    query PdvPointsById($pdvPointId: String!) {
  pdvPointsById(pdvPointId: $pdvPointId) {
    ...PdvPoint
  }
}
    ${PdvPointFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PdvPointsByIdGQL extends Apollo.Query<PdvPointsByIdQuery, PdvPointsByIdQueryVariables> {
    document = PdvPointsByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMultipleDistributionForUserDocument = gql`
    mutation CreateMultipleDistributionForUser($data: CreateMultipleDistributionUser!) {
  createMultipleDistributionForUser(data: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMultipleDistributionForUserGQL extends Apollo.Mutation<CreateMultipleDistributionForUserMutation, CreateMultipleDistributionForUserMutationVariables> {
    document = CreateMultipleDistributionForUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HighlightsDocument = gql`
    query highlights {
  highlights {
    ...Highlight
  }
}
    ${HighlightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class HighlightsGQL extends Apollo.Query<HighlightsQuery, HighlightsQueryVariables> {
    document = HighlightsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HighlightStoryDocument = gql`
    query HighlightStory($id: String!) {
  highlightStory(id: $id) {
    ...HighlightStory
  }
}
    ${HighlightStoryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class HighlightStoryGQL extends Apollo.Query<HighlightStoryQuery, HighlightStoryQueryVariables> {
    document = HighlightStoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListGoalUserVariavelDocument = gql`
    query listGoalUserVariavel($reference: String!) {
  listGoalUserVariavel(reference: $reference) {
    reference
    goal {
      goal
      description
      percent
      result
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListGoalUserVariavelGQL extends Apollo.Query<ListGoalUserVariavelQuery, ListGoalUserVariavelQueryVariables> {
    document = ListGoalUserVariavelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListGoalUserFixoDocument = gql`
    query listGoalUserFixo($reference: String!) {
  listGoalUserFixo(reference: $reference) {
    reference
    goal {
      goal
      description
      percent
      result
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListGoalUserFixoGQL extends Apollo.Query<ListGoalUserFixoQuery, ListGoalUserFixoQueryVariables> {
    document = ListGoalUserFixoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListGoalPdvVariavelDocument = gql`
    query listGoalPdvVariavel($reference: String!) {
  listGoalPdvVariavel(reference: $reference) {
    reference
    goal {
      goal
      description
      percent
      result
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListGoalPdvVariavelGQL extends Apollo.Query<ListGoalPdvVariavelQuery, ListGoalPdvVariavelQueryVariables> {
    document = ListGoalPdvVariavelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListGoalPdvFixoDocument = gql`
    query listGoalPdvFixo($reference: String!) {
  listGoalPdvFixo(reference: $reference) {
    reference
    goal {
      goal
      description
      percent
      result
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListGoalPdvFixoGQL extends Apollo.Query<ListGoalPdvFixoQuery, ListGoalPdvFixoQueryVariables> {
    document = ListGoalPdvFixoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PostListDocument = gql`
    query postList {
  postList {
    ...Post
  }
}
    ${PostFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PostListGQL extends Apollo.Query<PostListQuery, PostListQueryVariables> {
    document = PostListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PostCommentsApprovedByIdDocument = gql`
    query postCommentsApprovedById($id: String!) {
  postCommentsApprovedById(id: $id) {
    ...Post
  }
}
    ${PostFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PostCommentsApprovedByIdGQL extends Apollo.Query<PostCommentsApprovedByIdQuery, PostCommentsApprovedByIdQueryVariables> {
    document = PostCommentsApprovedByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserPostCommentDocument = gql`
    mutation createUserPostComment($input: CreateUserPostCommentInput!) {
  createUserPostComment(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserPostCommentGQL extends Apollo.Mutation<CreateUserPostCommentMutation, CreateUserPostCommentMutationVariables> {
    document = CreateUserPostCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserPostLikeDocument = gql`
    mutation createUserPostLike($input: String!) {
  createUserPostLike(postId: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserPostLikeGQL extends Apollo.Mutation<CreateUserPostLikeMutation, CreateUserPostLikeMutationVariables> {
    document = CreateUserPostLikeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NotificationsByUserDocument = gql`
    query notificationsByUser {
  notificationsByUser {
    ...UserNotificationDto
  }
}
    ${UserNotificationDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationsByUserGQL extends Apollo.Query<NotificationsByUserQuery, NotificationsByUserQueryVariables> {
    document = NotificationsByUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReadNotificationDocument = gql`
    mutation readNotification($userNotificationId: String!) {
  readNotification(userNotificationId: $userNotificationId) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ReadNotificationGQL extends Apollo.Mutation<ReadNotificationMutation, ReadNotificationMutationVariables> {
    document = ReadNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ReadAllNotificationDocument = gql`
    mutation readAllNotification {
  readAllNotifications {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ReadAllNotificationGQL extends Apollo.Mutation<ReadAllNotificationMutation, ReadAllNotificationMutationVariables> {
    document = ReadAllNotificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAllNotificationsDocument = gql`
    mutation deleteAllNotifications {
  deleteAllNotifications {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAllNotificationsGQL extends Apollo.Mutation<DeleteAllNotificationsMutation, DeleteAllNotificationsMutationVariables> {
    document = DeleteAllNotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const QuestionByQuizIdDocument = gql`
    query QuestionByQuizId($id: String!) {
  questionByQuizId(quizId: $id) {
    ...QuizQuestionDTO
  }
}
    ${QuizQuestionDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class QuestionByQuizIdGQL extends Apollo.Query<QuestionByQuizIdQuery, QuestionByQuizIdQueryVariables> {
    document = QuestionByQuizIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveQuestionAnswerDocument = gql`
    mutation saveQuestionAnswer($input: SaveQuestionAnswerInput!) {
  saveQuestionAnswer(data: $input) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveQuestionAnswerGQL extends Apollo.Mutation<SaveQuestionAnswerMutation, SaveQuestionAnswerMutationVariables> {
    document = SaveQuestionAnswerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AbortQuizDocument = gql`
    mutation AbortQuiz($quizId: String!) {
  abortQuiz(quizId: $quizId) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AbortQuizGQL extends Apollo.Mutation<AbortQuizMutation, AbortQuizMutationVariables> {
    document = AbortQuizDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FinishQuizDocument = gql`
    mutation FinishQuiz($input: String!) {
  finishQuiz(quizId: $input) {
    totalQuestions
    correctAnswers
    totalPoints
    percentageCorrect
    startedIn
    finishedIn
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FinishQuizGQL extends Apollo.Mutation<FinishQuizMutation, FinishQuizMutationVariables> {
    document = FinishQuizDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConsumeContentDocument = gql`
    mutation consumeContent($trainingClassId: String!) {
  consumeContent(trainingClassId: $trainingClassId) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ConsumeContentGQL extends Apollo.Mutation<ConsumeContentMutation, ConsumeContentMutationVariables> {
    document = ConsumeContentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RankingByUserDocument = gql`
    query rankingByUser($reference: String!) {
  rankingByUser(reference: $reference) {
    ...RankingDTO
  }
}
    ${RankingDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RankingByUserGQL extends Apollo.Query<RankingByUserQuery, RankingByUserQueryVariables> {
    document = RankingByUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RankingByPdvDocument = gql`
    query rankingByPdv($reference: String!) {
  rankingByPdv(reference: $reference) {
    ...RankingDTO
  }
}
    ${RankingDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RankingByPdvGQL extends Apollo.Query<RankingByPdvQuery, RankingByPdvQueryVariables> {
    document = RankingByPdvDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RankingAllUserDocument = gql`
    query rankingAllUser($reference: String!) {
  rankingAllUser(reference: $reference) {
    ...RankingAllDTO
  }
}
    ${RankingAllDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RankingAllUserGQL extends Apollo.Query<RankingAllUserQuery, RankingAllUserQueryVariables> {
    document = RankingAllUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RankingAllPdvDocument = gql`
    query rankingAllPdv($reference: String!) {
  rankingAllPdv(reference: $reference) {
    ...RankingAllDTO
  }
}
    ${RankingAllDtoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RankingAllPdvGQL extends Apollo.Query<RankingAllPdvQuery, RankingAllPdvQueryVariables> {
    document = RankingAllPdvDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateCpfDocument = gql`
    query ValidateCPF($cpf: String!) {
  validateCPF(cpf: $cpf)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateCpfGQL extends Apollo.Query<ValidateCpfQuery, ValidateCpfQueryVariables> {
    document = ValidateCpfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateEmailDocument = gql`
    query ValidateEmail($email: String!) {
  validateEmail(email: $email)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateEmailGQL extends Apollo.Query<ValidateEmailQuery, ValidateEmailQueryVariables> {
    document = ValidateEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListPdvDocument = gql`
    query listPdv {
  listPdv {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListPdvGQL extends Apollo.Query<ListPdvQuery, ListPdvQueryVariables> {
    document = ListPdvDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListJobRoleDocument = gql`
    query listJobRole {
  listJobRole {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListJobRoleGQL extends Apollo.Query<ListJobRoleQuery, ListJobRoleQueryVariables> {
    document = ListJobRoleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListAccessTypeDocument = gql`
    query listAccessType {
  listAccessType {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListAccessTypeGQL extends Apollo.Query<ListAccessTypeQuery, ListAccessTypeQueryVariables> {
    document = ListAccessTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SelfRegisterDocument = gql`
    mutation SelfRegister($data: SelfRegisterInput!) {
  selfRegister(data: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SelfRegisterGQL extends Apollo.Mutation<SelfRegisterMutation, SelfRegisterMutationVariables> {
    document = SelfRegisterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptRegulationDocument = gql`
    mutation AcceptRegulation($data: String!) {
  acceptRegulation(regulationId: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptRegulationGQL extends Apollo.Mutation<AcceptRegulationMutation, AcceptRegulationMutationVariables> {
    document = AcceptRegulationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptTermDocument = gql`
    mutation AcceptTerm($data: String!) {
  acceptTerm(termId: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptTermGQL extends Apollo.Mutation<AcceptTermMutation, AcceptTermMutationVariables> {
    document = AcceptTermDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TrainingsDocument = gql`
    query trainings {
  trainings {
    id
    title
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TrainingsGQL extends Apollo.Query<TrainingsQuery, TrainingsQueryVariables> {
    document = TrainingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TrainingDocument = gql`
    query Training($id: String!) {
  training(id: $id) {
    ...Training
  }
}
    ${TrainingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TrainingGQL extends Apollo.Query<TrainingQuery, TrainingQueryVariables> {
    document = TrainingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EnumByTypeDocument = gql`
    query EnumByType($type: String!) {
  enumByType(type: $type) {
    id
    type
    description
    value
    detail
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EnumByTypeGQL extends Apollo.Query<EnumByTypeQuery, EnumByTypeQueryVariables> {
    document = EnumByTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($data: UpdateUserProfileInput!) {
  updateUserProfile(data: $data) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserProfileGQL extends Apollo.Mutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables> {
    document = UpdateUserProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($data: AuthInput!) {
  login(data: $data) {
    ...AuthType
  }
}
    ${AuthTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!, $type: String!) {
  forgotPassword(email: $email, type: $type) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ForgotPasswordGQL extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    document = ForgotPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResetPasswordDocument = gql`
    mutation ResetPassword($data: ResetPasswordInput!) {
  resetPassword(data: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ResetPasswordGQL extends Apollo.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> {
    document = ResetPasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChangePasswordDocument = gql`
    mutation changePassword($data: ChangePasswordInput!) {
  changePassword(data: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ChangePasswordGQL extends Apollo.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables> {
    document = ChangePasswordDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FirstAccessDocument = gql`
    mutation FirstAccess($data: FirstAccessInput!) {
  firstAccess(data: $data) {
    ...DefaultMessage
  }
}
    ${DefaultMessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FirstAccessGQL extends Apollo.Mutation<FirstAccessMutation, FirstAccessMutationVariables> {
    document = FirstAccessDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FirstAccessValidationCodeDocument = gql`
    mutation FirstAccessValidationCode($data: FirstAccessValidationCodeInput!) {
  firstAccessValidationCode(data: $data) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FirstAccessValidationCodeGQL extends Apollo.Mutation<FirstAccessValidationCodeMutation, FirstAccessValidationCodeMutationVariables> {
    document = FirstAccessValidationCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FirstAccessRegisterUserDocument = gql`
    mutation firstAccessRegisterUser($data: FirstAccessRegisterUserInput!) {
  firstAccessRegisterUser(data: $data) {
    ...User
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FirstAccessRegisterUserGQL extends Apollo.Mutation<FirstAccessRegisterUserMutation, FirstAccessRegisterUserMutationVariables> {
    document = FirstAccessRegisterUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }